import React, { useEffect, useState } from "react";
import { Form, Input, notification, Radio, Skeleton, Space } from "antd";
import { useHistory } from "react-router";
import { PageMetaTags } from "Common";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";
import {
  getY11Dashboard,
  saveY11Dashboard
} from "../../../../services/destination";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";
import {
  deepMerge,
  GCSE_OPTIONS,
  getKeyValuePair,
  INTERESTS,
  isAnyFieldEditable,
  LEVELS,
  SECTORS,
  TYPE
} from "../../DestinationTracker/common.js";
import { formItems } from "./formItemSchema.js";

const Y11Dashboard = ({ user }) => {
  const pageHeading = "Y11 Dashboard";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getY11Dashboard({ query: payload })
      .then(response => {
        if (response && response.success) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setFormData(newData);
        } else {
          setEditable(false);
          throw new Error("Failed to fetch data");
        }
      })
      .catch(error => {
        console.log(error);
        setEditable(false);
        notification.error({ message: `Failed to fetch ${pageHeading} data` });
      })
      .finally(() => setGetLoading(false));
  };

  useEffect(() => {
    getDetails();
  }, [query.get("id")]);

  useEffect(() => {
    if (!formData) {
      return;
    }
    const formKeyValuePairs = getKeyValuePair(formData);
    form.setFieldsValue({ ...formKeyValuePairs });
  }, [formData]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    saveY11Dashboard({ body: values })
      .then(response => {
        if (response.success) {
          getDetails();
          notification.success({ message: `Saved the ${pageHeading}` });
        } else {
          throw new Error("Failed to save record");
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to save ${pageHeading}` });
      })
      .finally(() => setLoading(false));
  };

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            {item.name === "applied" ? (
              <Input
                data-cy={item.name}
                defaultValue={item.value === 1 ? " Yes" : "No"}
                readonly="true"
                placeholder={item.placeholder}
              />
            ) : (
              <Input
                data-cy={item.name}
                defaultValue={item.value}
                readonly="true"
                placeholder={item.placeholder}
              />
            )}
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !formData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input data-cy={item.name} disabled={!item.editable} />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderRadioElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !formData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Radio.Group
              data-cy={item.name}
              disabled={!formData["sector"].editable}
            >
              <Space direction="vertical">
                {item.options.map((option, index) => (
                  <Radio key={index} value={option.value}>
                    {option.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    );
  };

  return (
    <div data-cy="page-y11-dashboard" className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-y11-dashboard"
            form={form}
            onFinish={onFinish}
          >
            {formData &&
              Object.keys(formItems).map(key => {
                if (
                  formData[key].type === TYPE.input &&
                  formData[key].visibile
                ) {
                  return renderInputElement(formData[key]);
                } else if (
                  formData[key].type === TYPE.radio &&
                  formData[key].visibile
                ) {
                  return renderRadioElement(formData[key]);
                }
              })}

            {editable && (
              <div className="form-group">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export { Y11Dashboard };
