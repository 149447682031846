const commonDestinationTrackerRoutes = [
  {
    name: "Information & Notes",
    path: "/destination-tracker/information-notes"
  },
  {
    name: "September Guarantee",
    path: "/destination-tracker/september-guarantee",
    studentRoleName: "My Choices"
  },
  {
    name: "October Activity Survey",
    path: "/destination-tracker/october-activity-survey"
  },
  {
    name: "Student Data",
    path: "/destination-tracker/student-data",
    studentRoleName: "About Me"
  }
];

const destinationTracker = {
  "pre-16": [
    ...commonDestinationTrackerRoutes,
    {
      name: "KS4 Data",
      path: "/destination-tracker/ks4-data",
      studentRoleName: "Year 11"
    },
    {
      name: "KS4 Questionnaire",
      path: "/destination-tracker/ks4-questionnaire",
      studentRoleName: "Career Questionnaire"
    },
    { name: "Y11 Dashboard", path: "/destination-tracker/y11-dashboard" }
  ],
  "post-16": [
    ...commonDestinationTrackerRoutes,
    {
      name: "KS5 Studies",
      path: "/destination-tracker/ks5-studies"
    },
    {
      name: "Post-16 Live Dashboard",
      path: "/destination-tracker/may-return-live-dashboard"
    },
    {
      name: "Post 16 Questionnaire",
      path: "/destination-tracker/post-16-questionnaire"
    },
    {
      name: "Subjects / Options / UCAS",
      path: "/destination-tracker/subject-option-ucas"
    }
  ]
};

// Be careful usages:
// - Reports:
// - Post16Reports
const reports = [
  { name: "Report Y10 Dashboard", path: "/reports/y10-dashboard" },
  { name: "Report Y11 Dashboard", path: "/reports/y11-live-dashboard" },
  {
    name: "Report Y11 Leaver Dashboard",
    path: "/reports/y11-leaver-dashboard"
  },
  {
    name: "Report September Guarantee (Pre-16)",
    path: "/reports/september-guarantee"
  },
  {
    name: "Report October Activity Survey (Pre-16)",
    path: "/reports/october-survey"
  },
  { name: "Report FSM Dashboard (Pre-16)", path: "/reports/fsm-dashboard" },
  { name: "Report Send Dashboard (Pre-16)", path: "/reports/send-dashboard" },
  { name: "Report PP Dashboard (Pre-16)", path: "/reports/pp-dashboard" },
  { name: "Report LAC Dashboard (Pre-16)", path: "/reports/lac-dashboard" },
  { name: "Report EAL Dashboard (Pre-16)", path: "/reports/eal-dashboard" },
  { name: "Report Year 12 Dashboard", path: "/reports/y12-dashboard" },
  { name: "Report Year 13 Dashboard", path: "/reports/y13-dashboard" },
  {
    name: "Report Year 13 Leaver Dashboard",
    path: "/reports/y13-leaver-dashboard"
  },
  {
    name: "Report Sept Guarantee (Post-16)",
    path: "/reports/post16-september-guarantee"
  },
  {
    name: "Report Oct act surv (Post-16)",
    path: "/reports/post16-october-survey"
  },
  { name: "Report Bursary (Post-16)", path: "/reports/post16-bursary" },
  { name: "Report SEND (Post-16)", path: "/reports/post16-send" },
  { name: "Report LAC (Post-16)", path: "/reports/post16-lac" },
  { name: "Report EAL (Post-16)", path: "/reports/post16-eal" }
];

const student = [
  {
    name: "Dashboard",
    icon: "fa-tachometer-alt",
    path: "/dashboard",
    image: require("../../../assets/icons/sidebar/DashboardIcon.png")
  },
  {
    name: "My Adviser",
    icon: "fa-th",
    path: "/my-adviser",
    image: require("../../../assets/icons/sidebar/AdviserIcon.svg")
  },
  {
    name: "My Action Plan",
    icon: "fa-magic",
    path: "/action-plan",
    image: require("../../../assets/icons/sidebar/ActionPlanIcon.svg")
  },
  {
    name: "My Appointments",
    icon: "fa-sliders-h",
    path: "/appointments",
    image: require("../../../assets/icons/sidebar/AppointmentIcon.png")
  },
  {
    name: "My Future",
    icon: "fa-eye",
    path: "/my-future",
    image: require("../../../assets/icons/sidebar/FurureIcon.png")
  },
  {
    name: "Resources",
    icon: "fa-cog",
    path: "/resources",
    image: require("../../../assets/icons/sidebar/ResourcesIcon.png")
  }
];

const teacher = [
  { name: "Dashboard", icon: "fa-tachometer-alt", path: "/dashboard" },
  { name: "Student Search", icon: "fa-sort-amount-up", path: "/students" },
  { name: "Adviser Search", icon: "fa-check", path: "/adviser" },
  // { name: "Assets", icon: "fa-magic", path: "/assets" },
  { name: "Appointments", icon: "fa-sliders-h", path: "/appointments" },
  {
    name: "Appointment History",
    icon: "fa-history",
    path: "/appointments-history"
  },
  {
    name: "School Meeting Locations",
    icon: "fa-location-arrow",
    path: "/school-locations"
  },
  { name: "Reports", icon: "fa-th", path: "/reports" },
  { name: "Compass Report", icon: "fa-th", path: "/compass-report" },
  { name: "Import", icon: "fa-file-upload", path: "/import-data" }
];

const adviser = [
  { name: "Dashboard", icon: "fa-tachometer-alt", path: "/dashboard" },
  { name: "Appointments", icon: "fa-sliders-h", path: "/appointments" },
  {
    name: "Appointment History",
    icon: "fa-history",
    path: "/appointments-history"
  },
  { name: "Students List", icon: "fa-sort-amount-up", path: "/students" },
  { name: "My Tasks", icon: "fa-microchip", path: "/tasks" },
  { name: "Reports", icon: "fa-th", path: "/reports" },
  { name: "Useful Links", icon: "fa-draw-polygon", path: "/useful-links" },
  { name: "Time Logging", icon: "fa-wrench", path: "/timelog" },
  { name: "Emails", icon: "fa-envelope", path: "/emails/list" }
];

const superAdmin = [
  { name: "Student Search", icon: "fa-sort-amount-up", path: "/students" },
  { name: "Adviser Search", icon: "fa-check", path: "/adviser" },
  { name: "Location Search", icon: "fa-search-location", path: "/location" },
  { name: "Reports", icon: "fa-th", path: "/reports" }
];

/*
Note:
- BE returns "hyphens" in between "super-admin" const can't be hyphenated
*/
const roleBasedRoutes = {
  "super-admin": superAdmin,
  "school-admin": teacher // school-admin is now 'teacher' role
};

export { student, adviser, roleBasedRoutes, destinationTracker, reports };
