import React from "react";
import { Dropdown } from "antd";
import moment from "moment";
import { ADVISER, STUDENT } from "../../../../constants/rolesSubtypes";
import RoleCheck from "../../../helpers/RoleCheck";
import { UploadTaskDocuments } from "../UploadTaskDocuments";
import { MomentDateFormats } from "../../../../constants";

const TaskDropdownOverlay = ({
  task,
  removeTask,
  setTaskModal,
  setTaskDetails,
  setViewDocModal
}) => {
  const renderDropdownOverlay = task => {
    return (
      <div className="border bg-white overlay">
        <RoleCheck authority={[ADVISER]} visible={task && task.status === 1}>
          <div
            data-cy="edit-document"
            onClick={() => {
              setTaskDetails(task);
              setTaskModal(true);
            }}
            className="dropdown-overlay-edit"
          >
            <i className="fas fa-pen" /> Edit
          </div>
          <div
            data-cy="delete-document"
            onClick={() => removeTask(task.id)}
            className="dropdown-overlay-delete"
          >
            <i className="fas fa-trash" /> Delete
          </div>
        </RoleCheck>
        <RoleCheck authority={[ADVISER]} visible={task.status > 2}>
          <div
            onClick={() => {
              setTaskDetails(task);
              setViewDocModal(true);
            }}
            className="dropdown-overlay-edit"
          >
            <i className="fas fa-check" />
            {task.status === 3 ? "Approve" : "Disapprove"}
          </div>
        </RoleCheck>
        <RoleCheck authority={[STUDENT]}>
          <UploadTaskDocuments taskId={task.id} />
        </RoleCheck>
        <div
          data-cy="view-documents"
          onClick={() => {
            setTaskDetails(task);
            setViewDocModal(true);
          }}
          className="dropdown-overlay-edit"
        >
          <i className="fas fa-eye" /> View documents
        </div>
      </div>
    );
  };

  return (
    <div className="task-date">
      <div
        className="select-area dropdown"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <span className="date">
          {moment(task.completion_date).format(
            MomentDateFormats.CustomDateFormat.dayMonthYear
          )}
          <br />
        </span>
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          overlay={() => renderDropdownOverlay(task)}
        >
          <div data-cy={`view-options-${task.id}`} className="select-drop">
            <i className="fas fa-ellipsis-h" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export { TaskDropdownOverlay };
