import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { signInSuccessAction } from "Actions/authActions";
import { PageMetaTags } from "Common";
import { Link, useHistory } from "react-router-dom";
import { Form, notification } from "antd";
import { loginUser, thirdPartyLogin } from "../../../../services/user";
import { GoogleLogin } from "@react-oauth/google";
import { TwoFactorVerification } from "../../../modals";
import { STUDENT } from "../../../../constants";
import { verifyTwoFactor } from "../../../../services/common";
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import * as msal from "@azure/msal-browser";
// import MicrosoftLogin from "react-microsoft-login";

const LoginForm = ({ signInSuccess }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const ACCESS_TOKEN = "accessToken";
  const [loading, setLoading] = useState(false);
  const [show2FA, setShow2FA] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  const [loginResponse, setLoginResponse] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (window.zE) {
      window.zE(function() {
        window.zE.hide();
        window.zE("webWidget", "close");
      });
    }
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.zE) {
        window.zE(function() {
          window.zE.hide();
          window.zE("webWidget", "close");
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const responseGoogle = response => {
    if (!response.credential) {
      return;
    }
    const body = {
      token: response.credential,
      type: "google"
    };
    thirdPartyLogin({ body })
      .then(response => {
        if (response.success) {
          setLoginResponse(response);
        } else {
          notification.error({ message: "Failed to login" });
        }
      })
      .catch(error => {
        notification.error({ message: error.data.message });
      });
  };
  const responseMicrosoft = response => {
    if (!response.idToken) {
      return;
    }
    const body = {
      token: response.idToken,
      type: "graph"
    };
    thirdPartyLogin({ body })
      .then(res => {
        if (res.success) {
          setLoginResponse(res);
        } else {
          notification.error({ message: "Failed to login" });
        }
      })
      .catch(error => {
        notification.error({ message: error.data.message });
      });
  };
  // const login = useGoogleLogin({
  //   onSuccess: responseGoogle,
  //   onError: responseGoogle,
  //   // flow:"auth-code"
  // });
  useEffect(() => {
    if (loginResponse) {
      /*
       Imp!!
       Note: setting of this token has become mandatory to current architecture flow \
       as `getDefaultHeaders` use `ACCESS_TOKEN` directly to set headers value
       */
      localStorage.setItem(ACCESS_TOKEN, loginResponse.access_token);
      localStorage.setItem("exp_time", loginResponse.exp_time);
      localStorage.setItem("start_time", loginResponse.start_time);
      const { user } = loginResponse;

      // Note: OTP is for non Student role only
      if (user.role_slug !== STUDENT) {
        setShow2FA(true);
      } else {
        onVerificationComplete();
      }
    }
  }, [loginResponse]);

  useEffect(() => {
    if (show2FA && verificationCode) {
      if (verificationCode) {
        onTwoFactorVerification();
      } else {
        notification.error({
          message: "Verification failed. Please try again"
        });
      }
    }
  }, [show2FA, verificationCode]);

  const authHandler = (err, data) => {
    if (data.idToken.rawIdToken) {
      const body = {
        token: data.idToken.rawIdToken,
        type: "graph"
      };
      thirdPartyLogin({ body })
        .then(response => {
          if (response.success) {
            setLoginResponse(response);
          } else {
            notification.error({ message: "Failed to login" });
          }
        })
        .catch(error => {
          notification.error({ message: error.data.message });
        });
    }
  };
  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_GRAPH_LOGIN_CLIENT_ID,
      redirectUri: process.env.REACT_APP_MICROSOFT_LOGIN_REDIRECT_URI
    }
  };

  const myMSALObj = new msal.PublicClientApplication(msalConfig);
  // Function to initiate login
  const login = async () => {
    try {
      const loginRequest = {
        scopes: ["user.read"], // Example scopes
        prompt: "select_account"
      };
      myMSALObj
        .loginPopup(loginRequest)
        .then(loginResponse => {
          console.log(loginResponse, "loginResponse");
          responseMicrosoft(loginResponse);
        })
        .catch(error => {
          console.log(error);
        });
      // Handle post-login actions here, like getting user info or tokens for other resources
    } catch (error) {
      console.error(error);
    }
  };

  const renderThirdPartyLogins = () => {
    return (
      <div className="">
        <div className="social-div">
          <GoogleLogin
            logo_alignment="left"
            size="large"
            shape="square"
            width={!isTabletOrMobile ? "348" : "300"}
            onSuccess={responseGoogle}
            onError={responseGoogle}
          />
        </div>
        <div className="social-div">
          <button
            onClick={e => {
              e.preventDefault();
              login();
            }}
            className="microsoft-btn"
          >
            <div className="google-icon-wrapper">
              <img
                className="google-icon"
                src={require("../../../../assets/images/microsoft.png")}
                alt="microsoft"
              />
            </div>
            <p className="btn-text">Sign in with Microsoft</p>
          </button>
          {/* <MicrosoftLogin
            buttonTheme="light"
            className="microsoft-btn"
            clientId={process.env.REACT_APP_GRAPH_LOGIN_CLIENT_ID}
            graphScopes={["user.read"]}
            withUserData
            children={
              <button className="microsoft-btn">
                <div className="google-icon-wrapper">
                  <img
                    className="google-icon"
                    src={require("../../../../assets/images/microsoft.png")}
                    alt="microsoft"
                  />
                </div>
                <p className="btn-text">Sign in with Microsoft</p>
              </button>
            }
            authCallback={(error, data) => authHandler(error, data)}
            useLocalStorageCache
          />  */}
        </div>
      </div>
    );
  };

  const onFinish = values => {
    setLoading(true);
    loginUser({ body: values })
      .then(response => {
        setLoginResponse(response);
      })
      .catch(err => {
        setLoading(false);
        if (err && err.status === 401) {
          notification.error({ message: err.data.message });
        } else {
          notification.error({ message: "Failed to login" });
        }
      })
      .finally(() => setLoading(false));
  };

  const onVerificationComplete = () => {
    signInSuccess(loginResponse.user);
    history.push((location.state && location.state.from) || "/dashboard");
  };

  const onTwoFactorVerification = () => {
    verifyTwoFactor({
      otp: verificationCode
    })
      .then(response => {
        if (response.success) {
          setShow2FA(false);
          onVerificationComplete();
        } else {
          notification.error({
            message: "Failed to verify 2FA code"
          });
        }
      })
      .catch(error => {
        notification.error({ message: "Failed to verify 2FA code" });
      });
  };

  const onVerificationCode = code => {
    if (code) {
      setVerificationCode(code);
    }
  };

  const renderLoginForm = () => {
    return (
      <Form
        onFinish={onFinish}
        autoComplete="off"
        // initialValues={{
        //     email: "ahmed.sarfraz@sotechnology.co.uk",
        //     password: "12345678",
        // }}
      >
        <div data-cy="login-email" className="form-group-custom">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email"
              },
              {
                type: "email",
                message: "Please enter a valid email"
              }
            ]}
          >
            <input type="text" placeholder="Username" className="input-style" />
          </Form.Item>
        </div>
        <div data-cy="login-password" className="form-group-custom">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password"
              }
            ]}
          >
            <input
              type="password"
              className="input-style"
              placeholder="Password"
            />
          </Form.Item>
        </div>
        <div
          className="form-footer"
          style={{ textAlign: "left", paddingBottom: "12px" }}
        >
          <input
            data-cy="login-login"
            type="submit"
            disabled={loading}
            className="button-style button-style-green-btm button-style-fullwidth-button"
            value={loading ? "Loading..." : "Login"}
          />
          <Link
            data-cy="login-forgot-password"
            to="/forgot-password"
            className="forget-link"
          >
            Forgot password?
          </Link>
        </div>
      </Form>
    );
  };

  const STYLE_LOGIN_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };
  const STYLE_LOGIN_TOP_LOGO = {
    height: "60%",
    width: "60%"
  };
  const STYLE_LOGIN_Bottom_LOGO = {
    height: "40%",
    width: "40%"
  };

  return (
    <React.Fragment>
      <PageMetaTags title="Login" />
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-12 col-md-5">
            <div className="login-holder">
              <div className="login-inner text-center">
                <figure>
                  <img
                    style={{ ...STYLE_LOGIN_TOP_LOGO }}
                    src={require("../../../../assets/images/logo-small.png")}
                    alt="logo"
                  />
                </figure>
                <h1 className="page-heading">Sign in below</h1>
                {renderLoginForm()}
                {renderThirdPartyLogins()}
                <div className="footer-link">
                  <p className="powered-by">Powered by</p>
                  <figure>
                    <img
                      style={{
                        ...STYLE_LOGIN_Bottom_LOGO
                      }}
                      src={require("../../../../assets/images/logo-small1.png")}
                      alt="logo"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <img
              style={{ ...STYLE_LOGIN_IMAGE }}
              src={require("../../../../assets/images/iProgressLogin_1920_7cblock.jpg")}
              alt="login"
            />
          </div>
        </div>
      </div>

      {show2FA && (
        <TwoFactorVerification
          onVerificationCode={onVerificationCode}
          onCancel={() => {
            setVerificationCode(null);
            setShow2FA(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

LoginForm.propTypes = {
  // signInSuccess: PropTypes.func.isRequired,
  // history: PropTypes.shape({
  //     push: PropTypes.func.isRequired,
  // }).isRequired,
};

export default withContext(contextValue => {
  if (!contextValue && !contextValue?.dispatch) {
    return {};
  }
  const [state, dispatch] = contextValue;
  return {
    signInSuccess: data => signInSuccessAction(data, dispatch)
  };
}, LoginForm);
