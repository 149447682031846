import React from "react";
import { Breadcrumbs, PageMetaTags } from "../../../../components/common";

const renderBreadcrumbs = () => {
  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          name: "Resources",
          path: `/resources`
        },
        {
          name: "Skillsometer",
          path: null
        }
      ]}
    />
  );
};

const Skillsometer = () => {
  return (
    <div data-cy="page-skillsometer" style={{ height: "90vh", width: "100%" }}>
      <PageMetaTags title="Resources - Skillsometer" />
      {renderBreadcrumbs()}
      <h1>Resources - Skillsometer</h1>
      <iframe
        title="Skillsometer"
        src="https://www.lmiforall.org.uk/cm3/widget.html"
        width="100%"
        height="100%"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default Skillsometer;
