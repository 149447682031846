import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "antd";

import ActionPlanCard from "../ActionPlanCard";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import StudentFutureCards from "./StudentFutureCards";
import StudentNextAppointment from "./StudentNextAppointment";

const StudentDashboard = ({ user }) => {
  return (
    <div data-cy="page-dashboard">
      <HeaderWrapper
        title="Dashboard"
        image={require("../../../../assets/icons/header/icon-meter.png")}
      >
        <Row gutter={[24, 24]}>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <div
              data-cy="dashboard-agreed-actions-section"
              className="bg-white p-4 rounded-lg text-gray-500 h-full"
            >
              <div className="flex justify-between items-center">
                <div className="text-4xl text-gray-500 font-thin">
                  My Action Plan
                </div>
                <div className="">
                  <Link data-cy="view-all-actions" to="/action-plan">
                    <Button size="small" className="">
                      <i className="fas fa-ellipsis-h pr-1" /> View all actions
                    </Button>
                  </Link>
                </div>
              </div>

              <ActionPlanCard user={user} />
            </div>
          </Col>

          {/*Next Appointment Card*/}
          <StudentNextAppointment />
        </Row>

        {/*My Future Cards*/}
        <StudentFutureCards />
      </HeaderWrapper>
    </div>
  );
};

export default StudentDashboard;
