import React, { useEffect, useState } from "react";
import { PageMetaTags } from "Common";
import { withContext } from "Context";
import moment from "moment/moment";
import { getAdvisorsList } from "../../../../services/common";
import { Dropdown, Form, Input, Pagination, Row, Skeleton } from "antd";
import { Link } from "react-router-dom";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import BookAppointmentModal from "../../Appointments/BookAppointmentModal";
import { MomentDateFormats } from "../../../../constants";

const StudentAdvisorSearch = ({ user }) => {
  const pageHeading = "Adviser Search";
  const [advisors, setAdvisors] = useState();
  const [loading, setLoading] = useState(false);
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [form] = Form.useForm();

  const getAdvisor = query => {
    setLoading(true);
    getAdvisorsList({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: pageLimit,
        page: currentPage
      },
      query: query || {}
    })
      .then(res => {
        setLoading(false);
        setAdvisors(res);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getAdvisor();
  }, [currentPage, pageLimit]);

  const renderDropdownOverlay = advisor => {
    return (
      <div className="border bg-white overlay">
        <div
          onClick={() => {
            setBookAppointmentModal(true);
            setSelectedAdvisor(advisor);
          }}
          className="dropdown-overlay-edit"
        >
          <i className="fas fa-pen" /> Book Appointment
        </div>

        <Link style={{ color: "black" }} to="/appointments">
          <div className="dropdown-overlay-edit">
            <i className="fas fa-list" /> View Appointments
          </div>
        </Link>
        <Link style={{ color: "black" }} to={`/adviser/${advisor.id}`}>
          <div className="dropdown-overlay-edit">
            <i className="fas fa-eye" /> View Profile
          </div>
        </Link>
      </div>
    );
  };
  const renderPagination = () => {
    if (!advisors) {
      return;
    }
    return (
      <Row className="mb-2 mr-4 mt-4" type="flex" justify="end">
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          showSizeChanger
          defaultPageSize={pageLimit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setPageLimit(p)}
          total={(advisors && advisors.total_count) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} advisers`
          }
        />
      </Row>
    );
  };

  return (
    <HeaderWrapper title={pageHeading}>
      {bookAppointmentModal && (
        <BookAppointmentModal
          open={bookAppointmentModal}
          advisor={selectedAdvisor}
          userId={(selectedAdvisor && selectedAdvisor.id) || ""}
          setAdvisor={setSelectedAdvisor}
          onClose={setBookAppointmentModal}
          bookingFor="adviser"
        />
      )}
      <PageMetaTags title={pageHeading} />
      <Form
        data-cy="form-adviser-search"
        onFinish={values => getAdvisor(values)}
        form={form}
      >
        <div className="bg-white rounded-lg pb-4">
          <Skeleton loading={loading}>
            <div className="search-area px-4 pt-4">
              <div className="row">
                <div className="col-xl-3 col-md-12 col-lg-6">
                  <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                    <label className="labelstyle">Adviser Name</label>
                    <Form.Item name="search">
                      <Input
                        data-cy="name"
                        type="text"
                        placeholder="e.g. Richard Burchs"
                        className="input-style"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className=" col-xl-3 col-md-12 col-lg-6">
                  <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                    <label className="labelstyle">Specialisms</label>
                    <Form.Item name="special">
                      <Input
                        data-cy="specialisms"
                        type="text"
                        placeholder="e.g. Design"
                        className="input-style"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-12 d-flex justify-content-end align-items-end">
                  <span
                    onClick={() => form.submit()}
                    className="button-style button-style-green-btm button-style-normal-button"
                  >
                    Search
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col border rounded-lg mt-6 mx-4">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-gray-200 sm:rounded-lg">
                    <table className="min-w-full">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider"
                          >
                            Adviser Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider border-l"
                          >
                            Registration Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider border-l"
                          >
                            School Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-2 text-left text-sm font-medium text-gray-600 tracking-wider border-l"
                          >
                            Specialism
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {((advisors && advisors.user_list) || []).map(
                          advisor => (
                            <tr key={advisor.id}>
                              <td className="px-6 py-2 whitespace-nowrap">
                                <Link to={`/adviser/${advisor.id}`}>
                                  <div className="text-sm font-medium text-gray-900 hover:underline hover:text-green-600 cursor-pointer">
                                    {advisor.name}
                                  </div>
                                </Link>
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap border-l">
                                <div className="text-sm text-gray-900 capitalize">
                                  {moment(advisor.registration_date).format(
                                    MomentDateFormats.CustomDateFormat
                                      .dayMonthYear
                                  )}
                                </div>
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap border-l">
                                <span className="text-sm text-gray-900">
                                  {advisor.school_name || ""}
                                </span>
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 border-l">
                                <span className="text-sm text-gray-900">
                                  {advisor.specialism || ""}
                                </span>
                              </td>
                              <td className="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium border-l">
                                <Dropdown
                                  trigger={["click"]}
                                  placement="bottomRight"
                                  overlay={() => renderDropdownOverlay(advisor)}
                                >
                                  <i className="fas fa-ellipsis-h cursor-pointer" />
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {renderPagination()}
          </Skeleton>
        </div>
      </Form>
    </HeaderWrapper>
  );
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  StudentAdvisorSearch
);
