import React, { useEffect, useState } from "react";
import { Form, Input, notification, Radio, Skeleton, Space } from "antd";
import { useHistory } from "react-router";
import { PageMetaTags } from "Common";
import {
  getOctoberActivity,
  saveOctoberActivity,
  getDestinationDropdown
} from "../../../../services/destination";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import {
  GCSE_OPTIONS,
  LEVELS,
  SECTORS
} from "../../DestinationTracker/common.js";

const attendings = [
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" }
];
const moreFields = [
  { name: "elephant_group", label: "Elephant Group" },
  { name: "russell_group", label: "Russell Group" }
];

// Since both options list are same therefore used `GCSE_OPTIONS, LEVELS`=
const destinations = GCSE_OPTIONS;
const levels = LEVELS;

const OctoberActivity = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group
  const [destinationDrop, setDestinationDrop] = useState([]);
  const [sectorsDrop, setSectorsDrop] = useState([]);
  const [levelDrop, setLevelDrop] = useState([]);

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
      payload.year_group = query.get("year_group");
    }
    //for dynamic destination dynamic dropdown
    getDestinationDropdown({ query: payload })
      .then(response => {
        setDestinationDrop(response.destinationList);
        setSectorsDrop(response.sectorsList);
        setLevelDrop(response.levelList);
      })
      .catch(error => console.log(error));
    getOctoberActivity({ query: payload })
      .then(res => {
        if (res) {
          setFormData(res.data || undefined);
          setEditable(res.data && res.data.form_visible === 0 ? false : true);
          const values = Object.keys(res.data).reduce(
            (prev, k) => ({ ...prev, [k]: res.data[k].value }),
            {}
          );
          setGetLoading(false);
          form.setFieldsValue({ ...values });
        }
      })
      .catch(() => setGetLoading(false));
  };

  useEffect(() => getDetails(), [query.get("id")]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    saveOctoberActivity({ body: values })
      .then(res => {
        getDetails();
        setLoading(false);
        notification.success({ message: "Saved the October Activity Survey" });
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: "Failed to save October Activity Survey"
        });
      })
      .finally(() => setLoading(false));
  };

  const getLabel = (array, key) => {
    if (array && key) {
      const index = array.findIndex(a => a.id === key);
      return (array[index] && array[index].name && array[index].name) || key;
    }
    return "";
  };

  return (
    <div data-cy="page-october-activity-survey" className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      <PageMetaTags title="October Activity Survey" />
      <h1 className="page-heading">October Activity Survey</h1>
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-october-activity-survey"
            form={form}
            onFinish={onFinish}
          >
            <div className="">
              {formData && formData["attending"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Attending?</label>
                  <Form.Item name="attending">
                    {editable ? (
                      <Radio.Group
                        data-cy="attending"
                        disabled={
                          formData && formData["attending"]
                            ? !formData["attending"].editable
                            : false
                        }
                      >
                        {attendings.map(({ id, name }) => (
                          <Radio value={id}>{name}</Radio>
                        ))}
                      </Radio.Group>
                    ) : (
                      <div className="label-desc">
                        {formData && formData["attending"].value === 1
                          ? "Yes"
                          : "No"}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}
              {formData && formData["course"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Course</label>
                  <Form.Item name="course">
                    {editable ? (
                      <Input
                        data-cy="course"
                        disabled={
                          formData && formData["course"]
                            ? !formData["course"].editable
                            : false
                        }
                      />
                    ) : (
                      <div className="label-desc">
                        {formData && formData["course"].value}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}
              {formData && formData["destination"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Destination</label>
                  <Form.Item name="destination">
                    {editable ? (
                      <Radio.Group
                        data-cy="destination"
                        disabled={
                          formData && formData["destination"]
                            ? !formData["destination"].editable
                            : false
                        }
                      >
                        <Space direction="vertical">
                          {destinationDrop.map(({ value, label }) => (
                            <Radio value={value}>{label}</Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    ) : (
                      <div className="label-desc">
                        {getLabel(
                          destinationDrop,
                          formData && formData["destination"].value
                        )}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}

              {formData && formData["level"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Level</label>
                  <Form.Item name="level">
                    {editable ? (
                      <Radio.Group
                        data-cy="level"
                        disabled={
                          formData && formData["level"]
                            ? !formData["level"].editable
                            : false
                        }
                      >
                        {levelDrop.map(({ value, label }) => (
                          <Radio value={value}>{label}</Radio>
                        ))}
                      </Radio.Group>
                    ) : (
                      <div className="label-desc">
                        {getLabel(
                          levelDrop,
                          formData && formData["level"].value
                        )}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}

              {formData && formData["provider"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Provider</label>
                  <Form.Item name="provider">
                    {editable ? (
                      <Input
                        data-cy="provider"
                        disabled={
                          formData && formData["provider"]
                            ? !formData["provider"].editable
                            : false
                        }
                      />
                    ) : (
                      <div className="label-desc">
                        {formData && formData["provider"].value}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}

              {formData && formData["sector"].visibile && (
                <div className="form-group pb-2">
                  <label className="labelstyle">Sector</label>
                  <Form.Item name="sector">
                    {editable ? (
                      <Radio.Group
                        data-cy="sector"
                        disabled={
                          formData && formData["sector"]
                            ? !formData["sector"].editable
                            : false
                        }
                      >
                        <Space direction="vertical">
                          {sectorsDrop.map(i => (
                            <Radio value={i.value}>{i.label}</Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    ) : (
                      <div className="label-desc">
                        {getLabel(
                          sectorsDrop,
                          formData && formData["SECTORS"].value
                        )}
                      </div>
                    )}
                  </Form.Item>
                </div>
              )}

              {formData &&
                moreFields
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div key={name} className="form-group pb-2">
                      <label className="labelstyle">{label}</label>
                      <Form.Item name={name}>
                        {editable ? (
                          <Radio.Group
                            data-cy={name}
                            disabled={
                              formData && formData[name]
                                ? !formData[name].editable
                                : false
                            }
                          >
                            {attendings.map(({ id, name }) => (
                              <Radio value={id}>{name}</Radio>
                            ))}
                          </Radio.Group>
                        ) : (
                          <div className="label-desc">
                            {formData && formData[name].value === 1
                              ? "Yes"
                              : "No"}
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  ))}
            </div>
            {editable && (
              <div className="form-group d-flex justify-content-end">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default OctoberActivity;
