import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col, Skeleton } from "antd";
import { getNextAppointment } from "../../../../services/user";

const StudentNextAppointment = () => {
  const [loading, setLoading] = useState(false);
  const [nextAppointment, setNextAppointment] = useState();

  useEffect(() => {
    setLoading(true);
    getNextAppointment()
      .then(response => {
        setNextAppointment(response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const renderFormat = type => {
    switch (type) {
      case "live_chat":
        return "Live Chat";
      case "video_chat":
        return "Video Call";
      case "voice_chat":
        return "Voice Call";
      case "face":
        return "Face to Face Meeting";
      case "group_call":
        return "Careers Cafe Webinar";
      default:
        return "";
    }
  };
  return (
    <Col
      data-cy="dashboard-next-appointment-section"
      xl={12}
      lg={12}
      md={24}
      sm={24}
      xs={24}
    >
      <div className="bg-white p-4 rounded-lg text-gray-500 h-full">
        <div className="text-4xl font-thin">Next Appointment</div>
        <Skeleton loading={loading}>
          {(() => {
            if (nextAppointment && nextAppointment.appointment_date) {
              return (
                <div>
                  <div className="text mt-4">
                    <strong>Date:&nbsp;</strong>
                    {moment(nextAppointment.appointment_date).format(
                      "dddd, MMMM Do YYYY"
                    )}
                  </div>
                  <div className="text mt-2">
                    <strong>Time:&nbsp;</strong>
                    {moment(nextAppointment.appointment_date).format("h:mmA")}
                  </div>
                  <div className="text mt-2">
                    <strong>Format:&nbsp;</strong>
                    {renderFormat(nextAppointment.data.appointment_type)}
                  </div>
                  <div className="text mt-2">
                    <strong>With:&nbsp;</strong>
                    {nextAppointment.data.adviser.name}
                  </div>
                  <div className="text mt-2">
                    <strong>Preparing for your appointment:&nbsp;</strong>
                    <ul className="bullets">
                      <li>
                        Check your action plan and complete any tasks which you
                        have been set.
                      </li>
                      <li>
                        Explore the resources section for ideas and information
                        about possible careers
                      </li>
                      <li>
                        Make notes of any questions you would like to ask your
                        adviser.
                      </li>
                    </ul>
                  </div>
                  <div className="text mt-2">
                    <a
                      data-cy="join-call"
                      href={`/appointments/${nextAppointment.data.id}/join`}
                      className="button-style button-style-green-btm button-style-normal-button"
                      value="Start Video"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Join Call
                    </a>
                  </div>
                </div>
              );
            } else {
              return (
                <div>
                  <div className="text mt-4">
                    You don't have any appointments booked right now.
                  </div>
                </div>
              );
            }
          })()}
        </Skeleton>
      </div>
    </Col>
  );
};

export default StudentNextAppointment;
