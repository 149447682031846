import React, { useEffect, useState } from "react";
import { PageMetaTags } from "Common";
import { withContext } from "Context";
import {
  notification,
  Pagination,
  Spin,
  Table,
  Button,
  Popconfirm,
  Select,
  Modal
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DTFilter } from "../DT-Filter/DT-filter";
import { getSortOrder, openLinkInNewTab } from "../../../common";
import { updateInitialLoadFlag } from "../../../../store/actions";
import {
  clearStudentListFilter,
  getStudentsList,
  exportStudents,
  manageStudents
} from "../../../../services/common";
import {
  getSeptemberProvider,
  getOctoberProvider
} from "../../../../services/destination";

import {
  dataBinding,
  dataSetsReverseColumnMapping,
  fixedColumnsMapping,
  getColumnMetaData,
  variableColumnsMapping
} from "./dt-table-config";
import "./dt-table-styles.scss";
import { useHistory } from "react-router-dom";
import AddStudentModal from "../../Login/AddStudentModal";
import RoleCheck from "../../../helpers/RoleCheck";
import { SCHOOL_ADMIN } from "../../../../constants";

const StudentsList = props => {
  const pageHeading = "Student Search";
  const [students, setStudents] = useState();
  const [schoolName, setSchoolName] = useState();
  const [studentName, setStudentName] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [selectRow, setSelectRow] = useState();
  const [datasets, setDatasets] = useState([]);
  const [dtColumns, setDtColumns] = useState([]);
  const [dtData, setDtData] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [yearGroup, setYearGroup] = useState([]);
  const history = useHistory();
  const dateSetsReverseMapping = dataSetsReverseColumnMapping;
  const [modal, setModal] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userData, setUserData] = useState([]);
  const [value, setValue] = useState("live");

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const dataFilterColumn = params.get("data_filter_column");
    const yearGroupFilter = params.get("year_group_filter");
    const rag_data = params.get("rag_data");
    const reportFieldName = params.get("filter_column_key");
    const report_filter_field = params.get("report_filter_field");

    if (props.user.school_id === undefined) {
      if (localStorage.getItem("selected_school")) {
        props.user.school_id = JSON.parse(
          localStorage.getItem("selected_school")
        );
      }
    }

    if (dataFilterColumn) {
      let filter_field_names = "";
      let newRag = "";
      let newRagArr = "";
      let report_filter_field_value = "";
      if (dataFilterColumn === "informationNotesColumn") {
        filter_field_names = "live_rag";
        newRagArr = rag_data.split(" ");
        newRag = newRagArr[1];
      } else if (dataFilterColumn === "ks4QuestionnaireColumn") {
        if (typeof reportFieldName !== "undefined" && reportFieldName) {
          filter_field_names = reportFieldName;
          newRag = rag_data;
        } else {
          filter_field_names = "rag_for_priority_ks4";
          newRagArr = rag_data.split(" ");
          newRag = newRagArr[1];
        }
      } else if (dataFilterColumn === "post16QuestionnaireColumn") {
        if (typeof reportFieldName !== "undefined" && reportFieldName) {
          if (
            reportFieldName === "undefined" &&
            rag_data === "Needs urgent appointment"
          ) {
            filter_field_names = "rag_for_priority_16";
            newRagArr = rag_data.split(" ");
            newRag = 3;
          } else {
            filter_field_names = reportFieldName;
            newRag = rag_data;
          }
        } else {
          if (
            reportFieldName === "undefined" &&
            rag_data === "Needs urgent appointment"
          ) {
            filter_field_names = "rag_for_priority";
            newRagArr = rag_data.split(" ");
            newRag = 3;
          } else {
            filter_field_names = "rag_for_priority";
            newRagArr = rag_data.split(" ");
            newRag = newRagArr[1];
          }
        }
      } else {
        filter_field_names = reportFieldName;
        if (typeof rag_data !== "undefined" && rag_data) {
          newRag = rag_data;
        }
      }
      if (typeof report_filter_field !== "undefined" && report_filter_field) {
        report_filter_field_value = report_filter_field;
      }

      if (props.user && props.user.school_id) {
        getStudents({
          ...filterValue,
          school_id: props.user.school_id,
          data_filter_column: dataFilterColumn,
          year_group: yearGroupFilter,
          filter_field_name: filter_field_names,
          filter_field_data: newRag,
          report_filter_field: report_filter_field_value
        });
      } else {
        getStudents({
          ...filterValue,
          school_id: props.user.school_id,
          data_filter_column: dataFilterColumn,
          year_group: yearGroupFilter,
          filter_field_name: filter_field_names,
          filter_field_data: newRag,
          report_filter_field: report_filter_field_value
        });
      }
    } else {
      if (filteredInfo) {
        getStudents({
          ...filterValue,
          school_id: props.user.school_id,
          filter_field: filteredInfo
        });
      } else {
        getStudents({ ...filterValue, school_id: props.user.school_id });
      }
    }
  }, [
    currentPage,
    pageLimit,
    datasets,
    yearGroup,
    filterValue,
    props.user.school_id
  ]);

  useEffect(() => {
    if (students && students.success) {
      const fixedColumns = fixedColumnsMapping(students.dt_columns.fixed);
      let newColumns = [...fixedColumns];
      Object.keys(students.dt_columns).forEach(column => {
        if (column !== "fixed") {
          const columns = getColumnMetaData(
            dateSetsReverseMapping[column].title,
            variableColumnsMapping(students.dt_columns[column])
          );
          newColumns.push(columns);
        }
      });
      localStorage.setItem(
        "heIntention_arr",
        JSON.stringify(students.heIntentionList)
      );
      localStorage.setItem("level_arr", JSON.stringify(students.levelList));
      localStorage.setItem("sectors_arr", JSON.stringify(students.sectorsList));
      localStorage.setItem(
        "destination_arr",
        JSON.stringify(students.destinationList)
      );
      //for post 16
      localStorage.setItem(
        "destinationMay_arr",
        JSON.stringify(students.destinationMayList)
      );
      localStorage.setItem(
        "sectorsMay_arr",
        JSON.stringify(students.sectorsMayList)
      );
      localStorage.setItem(
        "destinationPostQuestionnaire_arr",
        JSON.stringify(students.destinationPostQuestionnaireList)
      );
      localStorage.setItem(
        "sectorsPostQuestionnaire_arr",
        JSON.stringify(students.sectorsPostQuestionnaireList)
      );
      localStorage.setItem(
        "sectorslevelMay_arr",
        JSON.stringify(students.levelMayList)
      );
      localStorage.setItem(
        "levelPostStudy_arr",
        JSON.stringify(students.levelPostStudyList)
      );
      localStorage.setItem(
        "selected_year_group",
        JSON.stringify(students.year_group)
      );
      let oldGroup = "";
      if (localStorage.getItem("selected_yeargroups") !== null) {
        oldGroup = JSON.parse(localStorage.getItem("selected_yeargroups"));
        localStorage.setItem(
          "selected_yeargroups",
          JSON.stringify(students.yeargroups)
        );
        if (userData.role_slug === "school-admin") {
          if (oldGroup !== students.yeargroups) {
            //console.log('both not eqak');
            setTimeout(function() {
              window.location.reload();
            }, 2000);
          }
        }
      } else {
        localStorage.setItem(
          "selected_yeargroups",
          JSON.stringify(students.yeargroups)
        );
      }
      //console.log(oldGroup+ " KK " +students.yeargroups);
      setLoading(true);
      const data = dataBinding(students.user_list);
      setDtData(data);
      setDtColumns(newColumns);
      setLoading(false);
    }
  }, [students]);

  useEffect(() => {
    if (selectRow) {
      navigateToStudentProfile(selectRow);
    }
  }, [selectRow]);
  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("user"));
    if (users) {
      setUserData(users);
    }

    getSeptemberProvider()
      .then(response => {
        if (response.length > 0) {
          localStorage.setItem("september_provider", JSON.stringify(response));
        } else {
          console.log("response");
        }
      })
      .catch(error => {
        console.log(error);
      });
    getOctoberProvider()
      .then(response => {
        if (response.length > 0) {
          localStorage.setItem("october_provider", JSON.stringify(response));
        } else {
          console.log("response");
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleDataSetsSelected = selectedDataSets => {
    props.updateInitialLoadFlag();
    setDatasets(selectedDataSets);
  };

  const handleYearGroupSelected = selectedYeargroup => {
    props.updateInitialLoadFlag();
    setYearGroup(selectedYeargroup);
  };

  const onFilterValueChange = value => {
    //console.log(value);
    props.updateInitialLoadFlag();
    setFilterValue(value);
  };

  const onClearFilter = () => {
    clearStudentListFilter({})
      .then(() => {
        setFilterValue({ search: null });
        setFilteredInfo();
        // EMPTY since BE clear filters:
      })
      .catch(error => console.log(error));
  };

  const getStudents = query => {
    setLoading(true);
    setSubHeading(query);
    setDtData();
    const body = {
      sort_column: (query && query.sort_field) || "id",
      sort_order: (query && query.sort_order) || "desc",
      filter_field_name: (query && query.filter_field_name) || "",
      filter_field_data: (query && query.filter_field_data) || "",
      student_type: value,
      page_limit: pageLimit,
      page: currentPage,
      initialLoad: props.studentsList && props.studentsList.initialLoad
    };
    if (datasets && datasets.length > 0) {
      body["data_sets"] = datasets.join(","); // BE Accepts "," seperated string
    }
    if (yearGroup && yearGroup.length > 0) {
      body["year_group"] = yearGroup;
    }

    getStudentsList({
      body: {
        ...body
      },
      query: query || {}
    })
      .then(response => {
        //setLoading(false);
        setStudents(response);
      })
      .catch(() => setLoading(false));
  };

  const setSubHeading = query => {
    if (query && query.school) {
      setSchoolName(query.school);
    } else {
      setSchoolName("");
    }
    if (query && query.search) {
      setStudentName(query.search);
    } else {
      setStudentName("");
    }
  };

  const navigateToStudentProfile = student => {
    if (student && student.id) {
      const link = `/students/${student.id}`;
      history.push(link);
    } else {
      notification.error({ message: "No student id exist" });
    }
  };

  const renderSubHeading = () => {
    if (!students) {
      return;
    }
    return (
      <h2
        className="page-sub-heading"
        style={{ float: "left", paddingRight: "10px" }}
      >
        There are <span>"{(students && students.total_count) || 0}"</span>{" "}
        results{" "}
        {(schoolName || studentName) && (
          <React.Fragment>
            for <span>{schoolName || studentName}</span>
          </React.Fragment>
        )}
      </h2>
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    //console.log(filters);
    let filters_data = JSON.stringify(filters);
    setFilteredInfo(filters_data);
    if (props.user && props.user.school_id) {
      getStudents({
        ...filterValue,
        sort_field: sorter.field,
        sort_order: getSortOrder(sorter.order),
        filter_field: filters_data,
        school_id: props.user.school_id
      });
    } else {
      getStudents({
        ...filterValue,
        sort_field: sorter.field,
        sort_order: getSortOrder(sorter.order),
        filter_field: filters_data
      });
    }
  };
  /* start confirm popup for download */
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setConfirmLoading(true);
    downloadExcel();
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  const downloadExcel = () => {
    const body = { year_group: students ? students.year_group : [] };
    exportStudents({
      ...body
    })
      .then(response => {
        const downloadURL = `${process.env.REACT_APP_API_URL}/export-school-students/${response}`;
        openLinkInNewTab(downloadURL);
        setConfirmLoading(false);
        notification.success({
          message: `Student list has been downloaded sucessfully.`
        });
      })
      .catch(() => {
        notification.success({ message: `Error in download file.` });
      });
    setOpen(false);
    setConfirmLoading(false);
  };

  const onSelectChange = newSelectedRowKeys => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  //for Action dropdown
  const handleSelectChange = val => {
    if (selectedRowKeys.length === 0) {
      notification.error({
        message: `Please select student from student list.`
      });
      return false;
    } else if (val === "progress_to_next_academic_year") {
      confirm(val);
    } else if (val === "archive") {
      confirm(val);
    } else if (val === "unarchive") {
      confirm(val);
    }
  };

  const handlelistingChange = value => {
    setValue(value);
    getStudents({
      student_type: value
    });
    console.log(`selected ${value}`);
  };

  const confirm = val => {
    if (val === "progress_to_next_academic_year") {
      Modal.confirm({
        title: "Progress to next year",
        content:
          "Are you sure you want to promote selected student to next year?",
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => {
          const body = {
            action: "progress_to_next_academic_year",
            studentArr: selectedRowKeys
          };
          manageStudents({
            ...body
          })
            .then(response => {
              if (response.success === false) {
                notification.error({ message: response.message });
              } else {
                notification.success({ message: response.message });
              }
              getStudents({
                student_type: value
              });
            })
            .catch(() => {
              notification.success({
                message: `Students has been promoted sucessfully.`
              });
              getStudents({
                student_type: value
              });
            });
          setSelectedRowKeys([]);
        }
      });
    } else if (val === "archive") {
      Modal.confirm({
        title: "Archive students",
        content: "Are you sure you want to archive students?",
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => {
          const body = { action: "archive", studentArr: selectedRowKeys };
          manageStudents({
            ...body
          })
            .then(response => {
              getStudents({
                student_type: value
              });
              notification.success({
                message: response`Students were archived successfully`
              });
            })
            .catch(() => {
              notification.success({
                message: `Students were archived successfully`
              });
            });

          setSelectedRowKeys([]);
        }
      });
    } else if (val === "unarchive") {
      Modal.confirm({
        title: "Unarchive students",
        content: "Are you sure you want to unarchive students?",
        icon: <ExclamationCircleOutlined />,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => {
          const body = { action: "unarchive", studentArr: selectedRowKeys };
          manageStudents({
            ...body
          })
            .then(response => {
              getStudents({
                student_type: value
              });
              notification.success({
                message: response`Students were unarchived successfully`
              });
            })
            .catch(() => {
              notification.success({
                message: `Students were unarchived successfully`
              });
            });

          setSelectedRowKeys([]);
        }
      });
    }
  };

  return (
    <div data-cy="page-students" className="content-panel">
      <PageMetaTags title={pageHeading} />
      {/*<h1 className="page-heading">{pageHeading}</h1> -->*/}

      <DTFilter
        clearFilters={onClearFilter}
        filterData={students ? students.search_data : {}}
        datasetDefaultValue={students ? students.data_sets : []}
        onFilterValueChange={onFilterValueChange}
        onDataSetsSelect={handleDataSetsSelected}
        onYearGroupSelect={handleYearGroupSelected}
        yearGroupDefaultValue={students ? students.year_group : []}
      />

      <div className="row">
        <div className="col-12 col-md-12">
          <RoleCheck authority={[SCHOOL_ADMIN]}>
            <b className="page-sub-heading">Showing </b>{" "}
            <Select
              defaultValue="live"
              value={value}
              style={{
                width: 100
              }}
              onChange={handlelistingChange}
              options={[
                {
                  value: "live",
                  label: "Live"
                },
                {
                  value: "archive",
                  label: "Archived"
                }
              ]}
            />{" "}
            <b className="page-sub-heading"> students</b>
          </RoleCheck>
        </div>
        <div className="col-12 col-md-6">
          {renderSubHeading()}
          <RoleCheck authority={[SCHOOL_ADMIN]}>
            {value === "live" ? (
              <Select
                defaultValue="Action"
                onChange={handleSelectChange}
                style={{ width: 250 }}
                allowClear
                options={[
                  {
                    value: "archive",
                    label: "Archive"
                  },
                  {
                    value: "progress_to_next_academic_year",
                    label: "Progress to next Academic year"
                  }
                ]}
              />
            ) : (
              <Select
                defaultValue="Action"
                onChange={handleSelectChange}
                style={{ width: 250 }}
                allowClear
                options={[
                  {
                    value: "unarchive",
                    label: "Unarchive"
                  },
                  {
                    value: "progress_to_next_academic_year",
                    label: "Progress to next Academic year"
                  }
                ]}
              />
            )}
          </RoleCheck>
        </div>
        <div
          className={
            "col-12" +
            (userData.role_slug === "school-admin" ? " col-md-6" : " col-md-6")
          }
        >
          {modal && (
            <AddStudentModal open={modal} onClose={() => setModal(false)} />
          )}
          <div
            onClick={() => setModal(true)}
            className="cursor-pointer float-right"
          >
            <Button
              data-cy="button-add-student"
              type="primary"
              htmlType="button"
            >
              Add Student
            </Button>
          </div>
        </div>
      </div>

      <div className="search-wrapper">
        <div className="table-responsive mobile-spacing">
          <Spin spinning={loading}>
            {userData.role_slug === "school-admin" ? (
              <Table
                id={"dt-table"}
                size="middle"
                bordered={true}
                columns={dtColumns}
                rowClassName={"cursor-pointer"}
                rowKey={record => record.id}
                dataSource={dtData}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                onRow={record => ({
                  onClick: event => {
                    event.preventDefault();
                    event.stopPropagation();
                    //commented for hide row click. it will be back if needed
                    //setSelectRow(record);
                  }
                })}
                scroll={{ y: 400 }}
                pagination={false}
              />
            ) : (
              <Table
                id={"dt-table"}
                size="middle"
                bordered={true}
                columns={dtColumns}
                rowClassName={"cursor-pointer"}
                rowKey={record => record.id}
                dataSource={dtData}
                onChange={handleTableChange}
                onRow={record => ({
                  onClick: event => {
                    event.preventDefault();
                    event.stopPropagation();
                    //commented for hide row click. it will be back if needed
                    //setSelectRow(record);
                  }
                })}
                scroll={{ y: 400 }}
                pagination={false}
              />
            )}

            <div className="d-flex justify-content-end mb-2 mr-2 mt-4">
              <Pagination
                current={currentPage}
                onChange={setCurrentPage}
                showSizeChanger
                defaultPageSize={pageLimit}
                pageSizeOptions={["50", "100", "200", "500", "1000", "10000"]}
                onShowSizeChange={(e, p) => setPageLimit(p)}
                total={(students && students.total_count) || 0}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} students`
                }
              />
            </div>
          </Spin>
        </div>
        {(props.user.data_export === 1 || props.user.data_export === "1") && (
          <div className="row">
            <div
              className="col-12 col-md-6"
              style={{
                marginLeft: 30,
                marginTop: -20,
                whiteSpace: "nowrap"
              }}
            >
              <RoleCheck authority={[SCHOOL_ADMIN]}>
                <Popconfirm
                  title="Kindly note that any downloading of student data is undertaken at 
            the sole risk of the downloader. We hereby disclaim all liability 
            and responsibility for any consequences arising from the unauthorised
             use or disclosure of such data."
                  description="Open Popconfirm with async logic"
                  open={open}
                  icon=""
                  okText="Agree"
                  placement="top"
                  onConfirm={handleOk}
                  okButtonProps={{
                    loading: confirmLoading
                  }}
                  onCancel={handleCancel}
                >
                  <i
                    className="fa fa-download ml-2 popoverhight"
                    id="popoverhight"
                    onClick={showPopconfirm}
                  ></i>
                </Popconfirm>
              </RoleCheck>
            </div>
            <div className="col-12 col-md-6"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withContext(
  ([{ user, studentsList }, dispatch]) => ({
    // State:
    user,
    studentsList,
    // Actions:
    updateInitialLoadFlag: () => updateInitialLoadFlag(dispatch)
  }),
  StudentsList
);
