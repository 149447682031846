import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import {
  DatePicker,
  Form,
  notification,
  Pagination,
  Row,
  Select,
  Table
} from "antd";
import { disabledRangeTime } from "../../../common/Utils/utils";
import { getBenchmark } from "../../../../services/compassreport";
import {
  getSchoolList,
  logTime,
  schoolStudents
} from "../../../../services/user";
import { MomentDateFormats } from "../../../../constants";
import { BenchMarks, ReversBenchMarksMapping } from "../../common";
import { PageMetaTags } from "Common";
import { getLocationList } from "../../../../services/common";

const { RangePicker } = DatePicker;

const columns = [
  { title: "School", dataIndex: "school_name" },
  { title: "Student", dataIndex: "student_name" },
  { title: "From date/time", dataIndex: "start_time" },
  { title: "To date/time", dataIndex: "end_time" },
  {
    title: "Gatsby Benchmarks",
    dataIndex: "benchmark",
    render: data => {
      return ReversBenchMarksMapping[data].name;
    }
  },
  { title: "Comments", dataIndex: "comments" }
];

const TimeLogForm = () => {
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [benchMarkData, setBenchMarkData] = useState();
  const [current, setCurrent] = useState(1);
  const [benchMarks] = useState(BenchMarks);

  useEffect(() => {
    getLocationList({
      body: {
        sort_column: "created_at",
        sort_order: "desc",
        page_limit: 100000,
        page: 1,
        is_header: 1 // "adviser"
      }
    })
      .then(res => setSchools(res.data))
      .catch(() => {});
  }, []);

  const getBenchMarkData = () =>
    getBenchmark({
      body: { page_limit: 10, activity_type: 2 },
      query: { page: current }
    }).then(res => {
      setBenchMarkData(res);
    });

  useEffect(() => {
    getBenchMarkData();
  }, [current]);

  const fetchSchoolStudents = id =>
    schoolStudents({ body: { page_limit: 10, school_id: id } })
      .then(res => setStudents(res))
      .catch(err => {});

  const logTimeSection = () => {
    return (
      <div className="col-md-8">
        <div className="log-wrapper">
          <Form
            data-cy="form-timelog"
            form={form}
            onFinish={val => {
              const body = {
                ...val,
                benchmark: val.benchmark.join(","),
                start_time: moment(val.log_time[0]).format(
                  MomentDateFormats.CustomDateFormat
                    .yearMonthDayHourMinutesSeconds
                ),
                end_time: moment(val.log_time[1]).format(
                  MomentDateFormats.CustomDateFormat
                    .yearMonthDayHourMinutesSeconds
                )
              };
              delete body.log_time;
              setLoading(true);
              logTime({ body })
                .then(res => {
                  getBenchMarkData();
                  notification.success({
                    message: res.message || `Time logged successfully!`
                  });
                  form.resetFields();
                  setLoading(false);
                })
                .catch(err => {
                  notification.error({
                    message:
                      err.message ||
                      err.data.message ||
                      `Failed to logged time!`
                  });
                  setLoading(false);
                });
            }}
          >
            <h2 className="page-sub-heading">Log time</h2>
            {/* <div>{schools[0]?.name || "Hello"}</div> */}

            <div className="form-group">
              <label className="labelstyle">Name of school</label>
              <Form.Item
                name="school_id"
                rules={[
                  {
                    required: true,
                    message: "Please select school."
                  }
                ]}
              >
                <Select
                  data-cy="schoolList-dropdown"
                  className="select-style select-style-medium-width"
                  placeholder="Select school"
                  onSelect={id => fetchSchoolStudents(id)}
                  data-testid="schoolList-dropdown"
                >
                  {schools
                    .filter(school => school.name !== "All")
                    .map(school => (
                      <Select.Option key={school.id} value={school.id}>
                        {school.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <div className="form-group">
              <label className="labelstyle">Student</label>
              <Form.Item
                name="linked_user"
                rules={[
                  {
                    required: false,
                    message: "Please select student."
                  }
                ]}
              >
                <Select
                  data-cy="studentsList-dropdown"
                  data-testid="studentsList-dropdown"
                  className="select-style select-style-medium-width"
                  placeholder="Select student"
                >
                  {students &&
                    Array.isArray(students.school_students) &&
                    students.school_students.map(student => (
                      <Select.Option
                        key={student.student_id}
                        value={student.student_id}
                      >
                        {student.student_name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group pb-4">
              <label className="labelstyle">Log Time</label>
              <Form.Item
                name="log_time"
                rules={[
                  {
                    required: true,
                    message: "Please select start and end date."
                  }
                ]}
              >
                <RangePicker
                  data-cy="range-picker"
                  className="select-style w-full"
                  disabledTime={disabledRangeTime}
                  showTime={{
                    hideDisabledOptions: true,
                    use12Hours: true,
                    defaultValue: [
                      moment(
                        "00:00",
                        MomentDateFormats.CustomDateFormat.hourMinute
                      ),
                      moment(
                        "11:59",
                        MomentDateFormats.CustomDateFormat.hourMinute
                      )
                    ]
                  }}
                  format={
                    MomentDateFormats.CustomDateFormat.dayMonthYearHourMinutes
                  }
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <label className="labelstyle">
                What gatsby benchmark does this relate to?
              </label>
              <Form.Item
                name="benchmark"
                rules={[
                  {
                    required: true,
                    message: "Please select the benchmark"
                  }
                ]}
              >
                <Select
                  data-cy="benchmarkList-dropdown"
                  mode="multiple"
                  className="select-style"
                  placeholder="Select BenchMark"
                >
                  {benchMarks.map(benchmark => (
                    <Select.Option key={benchmark.id} value={benchmark.id}>
                      {benchmark.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            {/* <div className="form-group">
              <label className="labelstyle">Score</label>
              <Form.Item name="description">
                <Input className="select-style-medium-width" />
              </Form.Item>
            </div> */}

            <div className="form-group mt-4">
              <label className="labelstyle">Comments</label>
              <Form.Item name="description">
                <TextArea
                  data-cy="field-description"
                  className="textarea-style "
                  rows={5}
                  id="comment"
                />
              </Form.Item>
            </div>
            <div className="form-group text-right flex flex-col md:flex-row gap-2 flex-wrap -mx-1.5">
              <div className="flex-1 px-1.5">
                <Link
                  data-cy="link-view-logs"
                  to="/timelog/list"
                  className="button-style button-style-green-btm button-style-normal-button w-full"
                >
                  View previous logs
                </Link>
              </div>
              <div className="flex-1 px-1.5">
                <input
                  type="submit"
                  className="button-style button-style-green-btm button-style-normal-button w-full"
                  value={loading ? "Loading..." : "Log"}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  };

  /*
    // EXPLICITLY HIDDEN AS PER SPRINT 6 CI-245 TICKET

    const renderMonthlyTotalSection = () => {
      return (
        <div className="col-md-4">
          <div className="progress-wrapper">
            <h2 className="page-sub-heading">Monthly Total</h2>
            <div className="progress-holder">
              <span>one</span>
              <div
                className="progress-bar"
                style={{ width: "93%", background: "#ffbd00" }}>
                {" "}
                <i>93%</i>
              </div>
            </div>
            <div className="progress-holder">
              <span>Two</span>
              <div
                className="progress-bar"
                style={{ width: "73%", background: "#0079ff" }}>
                {" "}
                <i>73%</i>
              </div>
            </div>
            <div className="progress-holder">
              <span>Three</span>
              <div
                className="progress-bar"
                style={{ width: "60%", background: "#30e46d" }}>
                {" "}
                <i>60%</i>
              </div>
            </div>
          </div>
        </div>
      );
    };
*/

  return (
    <>
      <div data-cy="page-timelog" className="content-panel">
        <PageMetaTags title="Time Logging" />
        <h1 className="page-heading">Time Logging</h1>
        <div className="profile-wrapper">
          <div className="row">
            {logTimeSection()}

            {/*EXPLICITLY HIDDEN AS PER SPRINT 6 CI-245 TICKET*/}
            {/*{renderMonthlyTotalSection()}*/}
          </div>
        </div>
        {benchMarkData && benchMarkData.total_count > 0 && (
          <div data-cy="table-timelog" className="profile-wrapper">
            <Table
              dataSource={(benchMarkData && benchMarkData.benchmark_list) || []}
              columns={columns}
              pagination={false}
              footer={() => (
                <Row
                  rowClassName={record => `row-timelog-${record.id}`}
                  className="mb-2 mr-2"
                  type="flex"
                  justify="end"
                >
                  <Pagination
                    current={current}
                    onChange={setCurrent}
                    total={(benchMarkData && benchMarkData.total_count) || 0}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} records`
                    }
                  />
                </Row>
              )}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TimeLogForm;
