import { Form, Input, notification, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { getToEmailUserList, sendEmail } from "Services/user";
import { debounce } from "lodash";
import { withContext } from "Context";
import { useHistory } from "react-router-dom";
import { isNumeric } from "../../../common/Utils/utils";

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function getQueryParameterValue(key) {
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  return parameters.get(key);
}

const SendEmail = ({ user }) => {
  const [toUserList, setToUserList] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [selectedSender, setSelectedSender] = useState();
  const [form] = Form.useForm();
  const history = useHistory();

  const editorConfig = {
    versionCheck: false,
    // Add other configuration options if needed
  };

  const userList = search =>
    getToEmailUserList({ body: { page_limit: 1000, search } })
      .then(res => setToUserList(res))
      .catch(() => {});

  const debounceSearch = debounce(userList, 1000);

  useEffect(() => {
    userList();
  }, []);

  useEffect(() => {
    const id = getQueryParameterValue("id");
    if (id) {
      setUserId(id);
    }
  }, [getQueryParameterValue("id")]);

  useEffect(() => {
    if (userId && toUserList && toUserList.user_list) {
      // Patch-fix Note:
      //  Patch fix: Inorder  to pre-select `To` directly setting form value `name` it should be `id`
      //  as BE accepts `id` while posting this need back to be converted to id before API call.
      const found = getUserBy(userId);
      if (found) {
        form.setFieldsValue({ to: found.name });
        setSelectedSender(found);
      }
    }
  }, [toUserList, userId]);

  const getUserBy = id => {
    if (id && toUserList && toUserList.user_list.length === 0) {
      return;
    }
    return toUserList.user_list.find(user => user.id === +userId);
  };

  const onFinish = val => {
    let body = { ...val, from: user.id };
    const { to } = body;

    if (!isNumeric(to) && selectedSender && selectedSender.id) {
      // See Patch-fix Note:
      const found = getUserBy(selectedSender.id);
      body = {
        ...body,
        to: +found.id
      };
    }

    setLoading(true);
    sendEmail({
      body: {
        ...body,
        show_body: stripHtml(body.mail_body).slice(0, 100)
      }
    })
      .then(res => {
        notification.success({
          message: res.message || `Time logged successfully!`
        });
        form.resetFields();
        setLoading(false);
        clearPreSelectionSenderState();
      })
      .catch(err => {
        notification.error({
          message: err.message || err.data.message || `Failed to logged time!`
        });
        setLoading(false);
      });
  };

  const clearPreSelectionSenderState = () => {
    window.history.replaceState(null, null, window.location.pathname);
    setUserId(null);
  };

  return (
    <div className="content-panel">
      <div className="profile-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="log-wrapper">
              <Form data-cy="from-send-email" form={form} onFinish={onFinish}>
                <h2 className="page-sub-heading cursor-pointer">
                  <span onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left mr-2" />
                  </span>
                  Send Email
                </h2>
                <div className="form-group">
                  <label className="labelstyle">To</label>
                  <Form.Item
                    name="to"
                    rules={[
                      {
                        required: true,
                        message: "Please select student."
                      }
                    ]}
                  >
                    <Select
                      data-cy="field-to"
                      showSearch
                      filterOption={false}
                      onSearch={text => debounceSearch(text)}
                      className="select-style "
                      placeholder={`Select ${
                        user.role_slug === "student" ? "adviser" : "student"
                      }`}
                    >
                      {toUserList &&
                        Array.isArray(toUserList.user_list) &&
                        toUserList.user_list.map(user => (
                          <Select.Option key={user.id} value={user.id}>
                            {user.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="labelstyle">Subject</label>
                  <Form.Item
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: "Please enter subject."
                      }
                    ]}
                  >
                    <Input
                      data-cy="field-subject"
                      placeholder="Enter Subject"
                    />
                  </Form.Item>
                </div>

                <div className="form-group">
                  <label className="labelstyle">Body</label>
                  <Form.Item name="mail_body">
                    <CKEditor
                      type="classic"
                      config={editorConfig}
                      onChange={data => {
                        form.setFieldsValue({
                          mail_body: data.editor.getData()
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="form-group text-right">
                  <input
                    type="submit"
                    className="button-style button-style-green-btm button-style-normal-button"
                    value={loading ? "Loading..." : "Send Email"}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), SendEmail);
