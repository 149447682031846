import React, { useState } from "react";
import { Empty, notification } from "antd";
import { withContext } from "Context";
import { changeTaskStatus } from "Services/task";
import { STUDENT } from "../../../../constants/rolesSubtypes";
import ViewDocsModal from "../ViewDocsModal";
import { storeTaskList } from "../../../../store/actions/taskActions";
import { TaskDropdownOverlay } from "../TaskDropdownOverlay";

const TaskList = ({
  user,
  tasks,
  setTaskModal,
  setTaskdetails,
  removeTask,
  taskdetails,
  storeTasks
}) => {
  const [viewDocModal, setViewDocModal] = useState(false);

  const changeStatus = task => {
    const status = task.status > 2 ? 2 : 3;
    updateTasks(status, task);
    changeTaskStatus({ pathParams: { taskId: task.id, statusId: status } })
      .then(res => {
        notification.success({
          message: res.message || "Task status has been updated successfully!"
        });
      })
      .catch(err => {
        updateTasks(task.status, task);
        notification.error({
          message: err.data.message || "Failed to update task status!"
        });
      });
  };

  const updateTasks = (status, task) => {
    const newTasks = tasks;
    const index = newTasks.taskList.findIndex(item => item.id === task.id);
    newTasks.taskList[index].status = status;
    storeTasks({ tasks: newTasks.taskList, total_count: newTasks.total });
  };

  if (!tasks.taskList.length) {
    return (
      <Empty description="Your adviser will agree Tasks with you during your Careers appointments" />
    );
  }

  return (
    <ul data-cy="task-listing" className="task-listing">
      {tasks.taskList.map(task => (
        <li key={task.id}>
          <label className="radio-style">
            <input
              onChange={() => user.role_slug === STUDENT && changeStatus(task)}
              type="checkbox"
              className="form-check-input"
              checked={task.status > 2}
            />
            <i />
            <p>{task && task.description}</p>
          </label>
          <TaskDropdownOverlay
            task={task}
            removeTask={removeTask}
            setTaskModal={setTaskModal}
            setTaskDetails={setTaskdetails}
            setViewDocModal={setViewDocModal}
          />
        </li>
      ))}

      {viewDocModal && (
        <ViewDocsModal
          visible={viewDocModal}
          setVisible={setViewDocModal}
          taskId={taskdetails && taskdetails.id}
        />
      )}
    </ul>
  );
};

export default withContext(
  ([{ tasks, user }, dispatch]) => ({
    tasks,
    user,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  TaskList
);
