import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Avatar, Empty, Pagination, Row, Skeleton } from "antd";
import { getInitials } from "../../../../../utils";
import { starEmail } from "../../../../../services/user";
import { MomentDateFormats } from "../../../../../constants";

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const SentList = ({
  emails,
  loading,
  current,
  setCurrent,
  limit,
  setLimit,
  sent,
  setEmail
}) => {
  const star = !sent ? "recepient_star" : "sender_star";

  const makeStarEmail = id => {
    starEmail({ body: { id } });
    const newEmails = { ...emails };
    const index = newEmails.mails.findIndex(email => email.id === id);
    console.log(index, id, newEmails.mails[index], newEmails);
    newEmails.mails[index][star] = !newEmails.mails[index][star];
    setEmail({ ...newEmails });
  };

  return (
    <Skeleton loading={loading}>
      {emails &&
        Array.isArray(emails.mails) &&
        emails.mails.map((item, index) => (
          <Link key={index} to={`/emails/${item.id}/view`}>
            <div
              style={{
                background: !sent && !item.read_status ? "#eef4f4" : "white",
                color: "black"
              }}
              className="d-flex bd-highlight align-items-center border-bottom py-2 emailItem"
            >
              <div className="p-2 bd-highlight">
                <Avatar style={{ background: "#14985f" }}>
                  {getInitials(item.sender_name)}
                </Avatar>
              </div>
              <div className="p-2 bd-highlight">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    makeStarEmail(item.id);
                  }}
                >
                  <i
                    style={{ fontSize: 18 }}
                    className={`${item[star] ? "fas" : "far"} fa-star`}
                  />
                </span>
              </div>
              <div className="font-weight-bold ml-2">{item.sender_name}</div>
              <div className="font-weight-normal ml-4">{item.subject} -</div>
              <div className="ml-2">
                {stripHtml(item.mail_body).slice(0, 100)}...
              </div>
              <div className="ml-auto p-2 bd-highlight">
                {moment(item.created_at).format(
                  MomentDateFormats.CustomDateFormat.dayMonthYear
                )}
              </div>
            </div>
          </Link>
        ))}

      {emails && emails.total_count > 0 && (
        <Row className="mr-2 my-2" type="flex" justify="end">
          <Pagination
            current={current}
            onChange={setCurrent}
            showSizeChanger
            defaultPageSize={limit}
            pageSizeOptions={["10", "25", "50", "100"]}
            onShowSizeChange={(e, p) => setLimit(p)}
            total={(emails && emails.total_count) || 0}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} emails`
            }
          />
        </Row>
      )}
      {emails && emails.total_count === 0 && <Empty description="No Emails" />}
    </Skeleton>
  );
};

export default SentList;
