import React, { useEffect, useState } from "react";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { Col, Row } from "antd";
import { getAppointments } from "../../../../services/appointment";
import { withContext } from "Context";
import { Link } from "react-router-dom";

const MyFuture = ({ user }) => {
  const [events, setEvents] = useState();
  const getAppointmentsList = query => {
    getAppointments({
      body: {
        sort_column: "id",
        page: 1,
        sort_order: "asc",
        page_limit: 100,
        filter_column: "student_id"
        // date_start: "2021-09-08T00:00:00Z",
        // date_end: moment.utc().format(),
      },
      query: query || {}
    })
      .then(response => setEvents(response.appointments))
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getAppointmentsList();
    console.log(events);
  }, []);

  const cards = {
    "pre-16": [
      {
        id: "about-me",
        name: "About Me...",
        image: require("../../../../assets/icons/cards/about_me.jpg"),
        path: "/destination-tracker/student-data"
      },
      {
        id: "my-choices",
        name: "My Choices...",
        image: require("../../../../assets/icons/cards/my_choices.jpg"),
        path: "/destination-tracker/september-guarantee"
      },
      {
        id: "my-adviser",
        name: "My Adviser...",
        image: require("../../../../assets/icons/cards/my_adviser.jpg"),
        path: "/my-adviser"
      },
      {
        id: "year-11",
        name: "Year 11...",
        image: require("../../../../assets/icons/cards/year_11.jpg"),
        path: "/destination-tracker/ks4-data"
      },
      {
        id: "career-questionnaire",
        name: "Career Questionnaire...",
        image: require("../../../../assets/icons/cards/career_questonnaire.jpg"),
        path: "/destination-tracker/ks4-questionnaire"
      },
      {
        id: "information-notes",
        name: "Information & Notes...",
        image: require("../../../../assets/icons/cards/information_notes.jpg"),
        path: "/destination-tracker/information-notes"
      }
    ],

    "post-16": [
      {
        id: "about-me",
        name: "About Me...",
        image: require("../../../../assets/icons/cards/about_me.jpg"),
        path: "/destination-tracker/student-data"
      },
      {
        id: "my-choices",
        name: "My Choices...",
        image: require("../../../../assets/icons/cards/my_choices.jpg"),
        path: "/destination-tracker/september-guarantee"
      },
      {
        id: "my-adviser",
        name: "My Adviser...",
        image: require("../../../../assets/icons/cards/my_adviser.jpg"),
        path: "/my-adviser"
      },
      {
        id: "year-11",
        name: "Year 11...",
        image: require("../../../../assets/icons/cards/year_11.jpg"),
        path: "/destination-tracker/y11-dashboard"
      },
      {
        id: "career-questionnaire",
        name: "Career Questionnaire...",
        image: require("../../../../assets/icons/cards/career_questonnaire.jpg"),
        path: "/destination-tracker/post-16-questionnaire"
      },
      {
        id: "information-notes",
        name: "Information & Notes...",
        image: require("../../../../assets/icons/cards/information_notes.jpg"),
        path: "/destination-tracker/information-notes"
      },
      {
        id: "may-return",
        name: "May Return...",
        image: require("../../../../assets/icons/cards/information_notes.jpg"),
        path: "/destination-tracker/may-return-live-dashboard"
      },
      {
        id: "subject-option-ucas",
        name: "Subject / Options / UCAS...",
        image: require("../../../../assets/icons/cards/information_notes.jpg"),
        path: "/destination-tracker/subject-option-ucas"
      }
    ]
  };

  return (
    <HeaderWrapper
      title="My Future"
      image={require("../../../../assets/icons/header/icon-eye.png")}
    >
      <div data-cy="page-my-future">
        <Row gutter={[24, 24]} className="mb-4">
          {user.year_group &&
            cards[user.year_group].map((card, index) => (
              <Col key={index} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Link data-cy={card.id} to={card.path}>
                  <div className="h-full bg-white rounded-lg text-gray-500">
                    <div className="text-3xl p-4 font-thin">{card.name}</div>
                    <div className="text-sm mt-2">
                      <img
                        className="h-56 rounded-br-lg rounded-bl-lg object-cover w-full"
                        alt="about me"
                        src={card.image}
                      />
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </div>
    </HeaderWrapper>
  );
};

export default withContext(([{ user }]) => ({ user }), MyFuture);
