import {
  Alert,
  Empty,
  Form,
  Modal,
  notification,
  Popconfirm,
  Select,
  Spin
} from "antd";
import moment from "moment";
import { withContext } from "Context";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import {
  createAppointment,
  deleteAppointment,
  getAppointmentSlots,
  showAdviserDaysOff,
  updateAppointment
} from "../../../../services/appointment";

import {
  ADVISER,
  APPOINTMENT_TYPES,
  MomentDateFormats
} from "../../../../constants";
import {
  getAppointmentType,
  getSchoolAppointmentLocations
} from "../../../../services/common";
import { AppointmentConfirmationStatus } from "./AppointmentConfirmationStatus/AppointmentConfirmationStatus";
import { deepMerge } from "../../DestinationTracker/common.js";

const durations = [
  { value: 30, name: "30 mins" },
  { value: 45, name: "45 mins" }
];

/**
 *
 * @param {Object} adviser - It is the object which contains the data of the user for whom we are booking appointments
 * @param {String} bookingFor - It is string which tell us for which role we are booking appointment and by default I kept adviser
 * @returns
 */

const BookAppointmentModal = ({
  event,
  open,
  onClose,
  advisor,
  setAdvisor,
  bookingFor,
  userId,
  // "withContext" to get User
  user,
  getAppointments
}) => {
  const [form] = Form.useForm();
  const [slots, setSlots] = useState();
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [viewAppointmentMode, setViewAppointmentMode] = useState(false);
  const [appointmentType, setAppointmentType] = useState("");
  const [selectedSlot, setSelectedSlot] = useState();
  const [selectedDate, setSelectedDate] = useState(moment.utc().format());
  const [showError, setShowError] = useState(false);
  const [successRes, setSuccessRes] = useState();
  const [loading, setLoading] = useState(false);
  const [filterAppointmentTypes, setFilterAppointmentTypes] = useState([]);
  const [schoolAppointmentLocations, setSchoolAppointmentLocations] = useState(
    []
  );
  const [daysMarkOff, setDaysDaysMarkOff] = useState([]);

  useEffect(() => {
    if (event) {
      setSelectedDate(moment(event.start));
      form.setFieldsValue({
        appointment_type: event.appointment_type,
        appointment_time: event.total_time
      });
      setAppointmentType(event.appointment_type);
      setViewAppointmentMode(true);
    }
  }, [event]);

  useEffect(() => {
    if (
      appointmentType !== APPOINTMENT_TYPES.face.value ||
      viewAppointmentMode
    ) {
      return;
    }
    // RUN ONLY FOR BOOKING MODE NOT FOR VIEW
    let query = {};
    if (user && user.role_slug === ADVISER) {
      query = { query: { student_id: userId } };
    }
    getSchoolAppointmentLocations(query)
      .then(response => {
        setLoading(false);
        if (response.success) {
          setSchoolAppointmentLocations(response.school_appointment_locations);
        } else {
          notification.error({
            message: response.message`Failed to fetch School locations`
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetch School locations` });
      })
      .finally(() => setLoading(false));
  }, [appointmentType]);

  useEffect(() => {
    let query = {};
    if (user && user.role_slug === ADVISER) {
      query = {
        query: { student_id: userId } // In case "student"
      };
    }
    getAppointmentType(query)
      .then(response => {
        if (response.success) {
          let filteredTypes = deepMerge(response);
          delete filteredTypes.success;
          filteredTypes = Object.keys(filteredTypes).filter(
            key => !!filteredTypes[key]
          );
          let newData = [];
          Object.values(filteredTypes).forEach(type => {
            if (type === APPOINTMENT_TYPES.face.value) {
              newData.push(APPOINTMENT_TYPES.face);
            } else if (type === APPOINTMENT_TYPES.video_chat.value) {
              newData.push(APPOINTMENT_TYPES.video_chat);
            } else if (type === APPOINTMENT_TYPES.voice_chat.value) {
              newData.push(APPOINTMENT_TYPES.voice_chat);
            } else if (type === APPOINTMENT_TYPES.live_chat.value) {
              newData.push(APPOINTMENT_TYPES.live_chat);
            } else if (type === APPOINTMENT_TYPES.group_call.value) {
              newData.push(APPOINTMENT_TYPES.group_call);
            }
          });
          setFilterAppointmentTypes(newData);
        } else {
          const appointmentTypes = Object.values(APPOINTMENT_TYPES).map(
            value => value
          );
          setFilterAppointmentTypes(appointmentTypes);
          console.log(response.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const getSlots = date => {
    setSlotsLoading(true);
    setSelectedSlot();
    if (user && user.role_slug === ADVISER) {
      userId = userId;
    } else {
      userId = "";
    }

    getAppointmentSlots({
      body: {
        advisor_id: advisor.id,
        student_id: userId,
        duration: form.getFieldValue(["appointment_time"]) || 30,
        date
      }
    })
      .then(data => {
        setSlotsLoading(false);
        setSlots(data);
      })
      .catch(() => setSlotsLoading(false));
  };

  useEffect(() => {
    if (user && user.role_slug === ADVISER) {
      userId = userId;
    } else {
      userId = "";
    }
    showAdviserDaysOff({ body: { advisor_id: advisor.id, student_id: userId } })
      .then(data => {
        setDaysDaysMarkOff(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const bookAppointment = body => {
    setLoading(true);
    createAppointment({ body })
      .then(res => {
        setSuccessRes(res);
        setTimeout(function() {
          window.location.reload();
        }, 3000);
      })
      .catch(err => {
        setSuccessRes();
      })
      .finally(() => {
        getAppointments();
        setLoading(false);
      });
  };

  const update = body => {
    setLoading(true);
    updateAppointment({ body })
      .then(res => {
        setSuccessRes(res);
        getAppointments();
      })
      .catch(err => {
        setSuccessRes();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (advisor) {
      getSlots(moment().format());
    }
  }, [advisor]);

  const deleteAppointments = () => {
    deleteAppointment({ body: { appointment_id: event.id } })
      .then(res => {
        if (res && res.success) {
          getAppointments();
          onClose(false);
          notification.success({
            message: res.message || "Appointment deleted successfully"
          });
        } else {
          notification.error({
            message: res.message || "Failed to delete appointment"
          });
        }
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to delete appointment"
        });
      });
  };

  const getSubmitTitle = () => {
    if (loading) {
      return "Loading...";
    }
    if (event) {
      return "Update Appointment";
    }
    return "Book Appointment";
  };

  const handleChange = value => {
    setAppointmentType(value);
  };

  return (
    <div className="modal fade" id="bookappointment">
      <Modal
        title={null}
        footer={null}
        visible={open}
        onCancel={() => onClose(false)}
        width={900}
        centered
        afterClose={() => {
          if (setAdvisor) {
            setAdvisor();
          }
          setSuccessRes();
          setSelectedSlot();
        }}
      >
        {!successRes && !(successRes && successRes.message) ? (
          <Form
            data-cy="form-book-appointment"
            form={form}
            onFinish={values => {
              setShowError(false);
              if (!selectedSlot) {
                setShowError(true);
              } else {
                const body = {
                  user_id: userId,
                  start_time: moment.utc(selectedSlot).format(),
                  end_time: moment
                    .utc(selectedSlot)
                    .add(Number(values.appointment_time), "minute")
                    .format(),
                  appointment_type: values.appointment_type,
                  booking_for: bookingFor || "adviser"
                };

                if (appointmentType === APPOINTMENT_TYPES.face.value) {
                  body.school_appointment_location_id =
                    values.school_appointment_location_id;
                }

                if (event) {
                  update({
                    appointment_id: event.id,
                    ...body
                  });
                } else {
                  bookAppointment(body);
                }
              }
            }}
            onFinishFailed={() => {
              setShowError(false);
              if (!selectedSlot) {
                setShowError(true);
              }
            }}
          >
            <div className="row">
              <div className="col-md-12 col-lg-4">
                <div className="form-group">
                  <label className="labelstyle">Appointment Type</label>
                  <Form.Item
                    name="appointment_type"
                    rules={[
                      {
                        required: true,
                        message: "Please select appointment type"
                      }
                    ]}
                  >
                    <Select
                      data-cy="dropdown-appointment-type"
                      placeholder="Please select appointment type"
                      className="select-style select-style-medium-width"
                      onChange={handleChange}
                    >
                      {filterAppointmentTypes.map(({ value, name }, index) => (
                        <option key={index} value={value}>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <label className="labelstyle">Appointment Time</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please select the appointment time"
                      }
                    ]}
                    name="appointment_time"
                  >
                    <Select
                      data-cy="dropdown-appointment-time"
                      onSelect={() => {
                        getSlots(selectedDate || moment().format());
                      }}
                      placeholder="Please select appointment time"
                      className="select-style select-style-medium-width"
                    >
                      {durations.map(item => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>

                {/* Display Locations dropdown on "face" appointment type*/}
                {appointmentType === APPOINTMENT_TYPES.face.value &&
                  (!viewAppointmentMode ? (
                    <div className="form-group">
                      <label className="labelstyle">Locations</label>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please select locations"
                          }
                        ]}
                        name="school_appointment_location_id"
                      >
                        <Select
                          data-cy="dropdown-locations"
                          placeholder="Please select location"
                          className="select-style select-style-medium-width"
                        >
                          {schoolAppointmentLocations.map(item => (
                            <option key={item.id} value={item.id}>
                              {item.location}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : (
                    <div className="form-group">
                      <label className="labelstyle">Location</label>
                      <label>{event.location || ""}</label>
                    </div>
                  ))}

                <div className="form-group">
                  {event &&
                    (event.appointment_type ===
                      APPOINTMENT_TYPES.video_chat.value ||
                      event.appointment_type ===
                        APPOINTMENT_TYPES.voice_chat.value ||
                      event.appointment_type ===
                        APPOINTMENT_TYPES.group_call.value ||
                      event.appointment_type ===
                        APPOINTMENT_TYPES.live_chat.value) && (
                      <a
                        href={`/appointments/${event.id}/join`}
                        className="button-style button-style-green-btm button-style-normal-button"
                        value="Start Video"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Join meeting
                      </a>
                    )}
                </div>
              </div>

              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <h2 className="page-sub-heading mb-4">
                    Select date &amp; Time{" "}
                  </h2>
                </div>

                <div className="row">
                  <div className="col-md-12 col-lg-7">
                    <div
                      className="calender-wrap"
                      style={{
                        minHeight: 360,
                        maxWidth: 310
                      }}
                    >
                      {showError && (
                        <Alert
                          message="Please select appointment slot."
                          type="error"
                          showIcon
                        />
                      )}
                      {/* Put calendar here */}
                      <Calendar
                        view="month"
                        onClickDay={date => {
                          setSelectedDate(moment(date).format());
                          getSlots(moment(date).format());
                        }}
                        value={new Date(selectedDate)}
                        defaultValue={new Date()}
                        tileContent={(date, view) => {
                          if (
                            moment(date.date).format(
                              MomentDateFormats.DayOfMonth.DD
                            ) ===
                            moment().format(MomentDateFormats.DayOfMonth.DD)
                          ) {
                            return (
                              <div
                                style={{
                                  position: "fixed",
                                  paddingLeft: "12px",
                                  marginTop: -8
                                }}
                              >
                                .
                              </div>
                            );
                          }
                        }}
                        showNeighboringMonth={false}
                        tileDisabled={(v, date) => {
                          if (
                            moment(v.date).format("DD-MMM-YYYY") ===
                            moment().format("DD-MMM-YYYY")
                          ) {
                            //console.log( moment(v.date).format('DD-MMM-YYYY'));
                            //console.log(moment().format('DD-MMM-YYYY'));
                            // Don't disable today date:
                            return false;
                          }
                          if (
                            daysMarkOff.find(
                              day =>
                                day ===
                                moment(v.date).format(
                                  MomentDateFormats.DayOfWeek.dddd
                                )
                            )
                          ) {
                            return true;
                          }

                          return moment(v.date).isBefore(moment(date));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-5">
                    <div className="current-date">
                      {moment(selectedDate).format("LL")}
                    </div>

                    <Spin spinning={slotsLoading}>
                      <ul className="time-slot-list">
                        {slots &&
                          Array.isArray(slots.slots) &&
                          slots.slots.map((slot, index) => (
                            <li key={index}>
                              <div
                                onClick={() => {
                                  setShowError(false);
                                  setSelectedSlot(slot);
                                }}
                                className={
                                  selectedSlot === slot
                                    ? "selected-slot"
                                    : "slot"
                                }
                              >
                                {moment(slot)
                                  .subtract(
                                    moment(slot).isDST() ? 1 : 0,
                                    "hours"
                                  )
                                  .format("LT")}
                              </div>
                            </li>
                          ))}
                        {slots && slots.message && (
                          <Empty description={slots.message} />
                        )}
                      </ul>
                    </Spin>
                  </div>
                </div>

                <div className="row">
                  {event && (
                    <Popconfirm
                      onConfirm={deleteAppointments}
                      title="Are you sure you want to cancel the appointment?"
                      okText="Yes"
                      okType="danger"
                      cancelText="No"
                    >
                      <Link
                        // onClick={deleteAppointments}
                        className="button-style button-style-normal-button mr-2"
                      >
                        Cancel Appointment
                      </Link>
                    </Popconfirm>
                  )}

                  <input
                    type="submit"
                    disabled={loading}
                    className="button-style button-style-green-btm button-style-normal-button"
                    value={getSubmitTitle()}
                  />
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <AppointmentConfirmationStatus
            user={user}
            appointmentConfirmation={successRes}
          />
        )}
      </Modal>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), BookAppointmentModal);
