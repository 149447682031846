import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { storeTaskList } from "../../../../store/actions/taskActions";
import { changeAdvisorTaskStatus, getAdvisorTasks } from "Services/task";
import { notification, Pagination, Row, Spin } from "antd";
import { PageMetaTags } from "Common";
import moment from "moment";

const AdvisorTasks = ({ tasks, storeTasks }) => {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  //improvement:use custom hook
  const fetchAdviserTasks = () => {
    setLoading(true);
    getAdvisorTasks({
      body: {
        sort_column: "id",
        sort_order: "asc",
        role_id: 3,
        page_limit: limit
      },
      query: { page: current }
    })
      .then(res => {
        storeTasks(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => fetchAdviserTasks(), []);

  const changeStatus = task => {
    const status = task.status > 2 ? 1 : 3;
    updateTasks(status, task);
    changeAdvisorTaskStatus({
      body: { task_log_id: task.advisor_task_log_id, status }
    })
      .then(res => {
        notification.success({
          message: res.message || "Task status has been updated successfully!"
        });
      })
      .catch(err => {
        updateTasks(task.status > 2 ? 3 : 2, task);
        notification.error({
          message: err.data.message || "Failed to update task status!"
        });
      });
  };

  const updateTasks = (status, task) => {
    const newTasks = tasks;
    const index = newTasks.taskList.findIndex(
      item => item.advisor_task_log_id === task.advisor_task_log_id
    );
    newTasks.taskList[index].status = status;
    storeTasks({ tasks: newTasks.taskList, total_count: newTasks.total });
  };

  return (
    <div data-cy="page-tasks" className="content-panel">
      <div className="student-booking">
        <PageMetaTags title="My Tasks" />
        <h1 className="page-heading">My Tasks</h1>
      </div>

      <div className="profile-wrapper p-0">
        <div className="table-responsive mobile-spacing">
          <div className="list-heading">
            <i className="fas fa-check" /> Tasks
          </div>
          <Spin spinning={loading}>
            <ul data-cy="mytasks-list" className="task-listing">
              {Array(tasks.taskList) &&
                tasks.taskList.map(task => (
                  <li key={task.id}>
                    <label className="radio-style">
                      <input
                        onClick={() => changeStatus(task)}
                        type="checkbox"
                        className="form-check-input"
                        checked={task.status > 2}
                      />
                      <i />
                      <p>{task && task.description}</p>
                    </label>
                    <div className="task-date">
                      <div
                        className="select-area dropdown"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="date">
                          {moment(task.completion_date).format("l")}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>

            {tasks && tasks.total > 0 && (
              <Row className="mb-2 mr-2 mt-4" type="flex" justify="end">
                <Pagination
                  current={current}
                  onChange={setCurrent}
                  showSizeChanger
                  defaultPageSize={limit}
                  pageSizeOptions={["10", "25", "50", "100"]}
                  onShowSizeChange={(e, p) => setLimit(p)}
                  total={(tasks && tasks.total) || 0}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} tasks`
                  }
                />
              </Row>
            )}

            {tasks.total === 0 && (
              <div className="text-center my-4 text-xl-start empty-tasks">
                No task available
              </div>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default withContext(
  ([{ tasks }, dispatch]) => ({
    tasks,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  AdvisorTasks
);
