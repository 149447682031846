import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Col,
  Form,
  Input,
  notification,
  Pagination,
  Row,
  Skeleton,
  Tooltip
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import TaskList from "../TaskList";
import { deleteTask, getTasks } from "Services/task";
import { PageMetaTags } from "../../../common";
import { MomentDateFormats, STUDENT } from "../../../../constants";
import { getActionCommonDetails } from "Services/common";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { ModalWrapper } from "../../../modals/shared/ModalWrapper";
import { storeTaskList } from "../../../../store/actions/taskActions";
import {
  getRevisionDateActionPlanDetails,
  getRevisionDatesVersions
} from "../../../../services/common";

const StudentActionPlan = ({ tasks, storeTasks, user }) => {
  const [taskModal, setTaskModal] = useState(false);
  const [taskdetails, setTaskdetails] = useState();
  const [fieldsData, setFieldsData] = useState();
  const [knowdata, setKnowdata] = useState();
  const [understanddata, setUnderstanddata] = useState();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  const { studentId } = useParams();
  const [form] = Form.useForm();
  const [versions, setVersions] = useState([]);
  const [versionPayload, setVersionPayload] = useState([]);
  const [selectedVersionDate, setSelectedVersionDate] = useState();
  const [showVersionModal, setShowVersionModal] = useState(false);

  useEffect(() => {
    fetchTasks();
  }, [current, limit]);

  useEffect(() => {
    // Role: Adviser, SchoolAdmin, SuperAdmin
    if (user && user.role_slug !== STUDENT && studentId) {
      getFieldDetails();
    } else if (user && user.role_slug === STUDENT && user.id) {
      // id "student id"
      getFieldDetails(user.id);
    }
  }, [studentId, user && user.id]);

  // Previous versions
  useEffect(() => {
    if (user && user.role_slug === STUDENT && user.id) {
      // id "student id"
      getVersions();
    }
  }, [user && user.id]);

  useEffect(() => {
    if (selectedVersionDate) {
      getSelectedVersionBy(selectedVersionDate);
    }
  }, [selectedVersionDate]);

  const getVersions = () => {
    setLoading(true);
    getRevisionDatesVersions({})
      .then(response => {
        if (response.success) {
          let dates = response.dates;
          dates = dates.map(item => {
            return moment(item.date).format(
              MomentDateFormats.CustomDateFormat.yearMonthDay
            );
          });

          setVersions(dates);
        } else {
          setVersions([]);
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetched previous version` });
        setVersions([]);
      })
      .finally(() => setLoading(false));
  };

  const getSelectedVersionBy = versionDate => {
    setLoading(true);
    getRevisionDateActionPlanDetails({ date: versionDate })
      .then(response => {
        if (response.success) {
          if (response["date_to_display"]) {
            response["date_to_display"] = moment(
              response["date_to_display"]
            ).format(MomentDateFormats.CustomDateFormat.yearMonthDay);
          }
          setVersionPayload(response);
        } else {
          notification.info({
            message: `Failed to fetch ${selectedVersionDate} data`
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to fetched previous version ${selectedVersionDate} data`
        });
      })
      .finally(() => setLoading(false));
  };

  const removeTask = id => {
    deleteTask({ body: { task_id: id } })
      .then(res => {
        fetchTasks();
        if (res && res.success) {
          notification.success({
            message: res.message || "Task delete successfully!"
          });
        } else {
          throw Error;
        }
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to delete task"
        });
      });
  };

  const fetchTasks = () => {
    setLoading(true);

    const query = { page: current };
    if (studentId) {
      query.student = studentId;
    }
    getTasks({
      body: {
        sort_column: "id",
        sort_order: "desc",
        role_id: 3,
        page_limit: limit
      },
      query
    })
      .then(res => {
        storeTasks(res);
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetched` });
      })
      .finally(() => setLoading(false));
  };

  const renderPagination = () => {
    return (
      <Row className="mr-2 mb-3" type="flex" justify="end">
        <Pagination
          current={current}
          onChange={setCurrent}
          showSizeChanger
          defaultPageSize={limit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setLimit(p)}
          total={(tasks && tasks.total) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} tasks`
          }
        />
      </Row>
    );
  };

  const getFieldDetails = id => {
    getActionCommonDetails({
      query: { student_id: studentId || id }
    })
      .then(response => {
        if (response.success) {
          setFieldsData(response);
          setKnowdata(response.know);
          setUnderstanddata(response.understand);
          form.setFieldsValue({
            know: response.know,
            understand: response.understand,
            old_know: response.old_know,
            old_understand: response.old_understand
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetched fields data` });
      })
      .finally(() => setLoading(false));
  };

  const renderKnowAndUnderstandSection = () => {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div className="text-gray-600 text-3xl mb-2">
            Know &nbsp;
            <Tooltip title="This section describes your starting point at the beginning of our meeting.">
              <InfoCircleOutlined style={{ fontSize: "18px" }} />
            </Tooltip>
          </div>
          <a
            data-cy="download-pdf"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_API_URL}/action-plan-download/${user.id}`}
          >
            <div className="flex items-center text-gray-600">
              <img
                alt="pdf"
                className="w-6 h-8 mr-2"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/PDF_icon.svg/896px-PDF_icon.svg.png"
              />
              Download PDF
            </div>
          </a>
        </div>
        <div
          data-cy="know"
          className="know-area"
          dangerouslySetInnerHTML={{ __html: knowdata }}
        />
        {/*<Input.TextArea
          autoSize={{ minRows: 5, maxRows: 8 }}
          placeholder="Your adviser will complete this with you during your Careers appointments"
          value={(fieldsData && fieldsData.know) || ""}
    />*/}
        <div className="text-gray-600 text-3xl mb-2 mt-4">
          Understand &nbsp;
          <Tooltip title="This section explains what we discussed and your understanding of the next steps.">
            <InfoCircleOutlined style={{ fontSize: "18px" }} />
          </Tooltip>
        </div>
        <div
          data-cy="understand"
          className="know-area"
          dangerouslySetInnerHTML={{ __html: understanddata }}
        />
        {/*<Input.TextArea
          autoSize={{ minRows: 5, maxRows: 8 }}
          placeholder="Your adviser will complete this with you during your Careers appointments"
          value={(fieldsData && fieldsData.understand) || ""}
        />*/}
      </div>
    );
  };

  const renderTasksSection = () => {
    return (
      <div>
        <div className="text-gray-600 text-3xl mb-2 mt-4">Tasks</div>
        <div className="border rounded-lg">
          <TaskList
            setTaskdetails={setTaskdetails}
            setTaskModal={setTaskModal}
            taskdetails={taskdetails}
            removeTask={removeTask}
          />
          {renderPagination()}
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <ModalWrapper
        title={`Viewing action plan from ${selectedVersionDate}`}
        width={600}
        footer={null}
        visible={true}
        onCancel={() => setShowVersionModal(false)}
        afterClose={() => setShowVersionModal(false)}
      >
        {versionPayload && (
          <div className="row align-items-end">
            <div className="col-12">
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: 16
                }}
              >
                Know &nbsp;
                <Tooltip title="This section describes your starting point at the beginning of our meeting.">
                  <InfoCircleOutlined style={{ fontSize: "18px" }} />
                </Tooltip>
              </label>
              <p>{versionPayload.know_value}</p>
            </div>
            <div className="col-12">
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: 16
                }}
              >
                Understand &nbsp;
                <Tooltip title="This section explains what we discussed and your understanding of the next steps.">
                  <InfoCircleOutlined style={{ fontSize: "16px" }} />
                </Tooltip>
              </label>
              <p>{versionPayload.understand_value}</p>
            </div>
          </div>
        )}
      </ModalWrapper>
    );
  };

  const onVersionClick = e => {
    e.stopPropagation();
    const date = e.target && e.target.innerText;
    setShowVersionModal(true);
    setSelectedVersionDate(date);
  };

  return (
    <HeaderWrapper
      title="My Action Plan"
      image={require("../../../../assets/icons/header/icon-diary.png")}
    >
      <PageMetaTags title="Action Plan" />
      <div data-cy="page-action-plan">
        <Row gutter={[24, 24]} className="mb-4">
          <Col xl={16} lg={16} md={24} sm={24} xs={24}>
            <div className="bg-white rounded-lg h-full p-4 mb-4">
              <Skeleton loading={loading}>
                {renderKnowAndUnderstandSection()}

                {versions && versions.length > 0 && (
                  <div className="flex flex-wrap items-center mt-6">
                    <div className="">VERSION</div>
                    {versions.map((date, index) => (
                      <div
                        key={index}
                        className="border rounded-lg px-2 mx-2 cursor-pointer"
                        onClick={onVersionClick}
                      >
                        {date}
                      </div>
                    ))}
                  </div>
                )}

                {showVersionModal && renderModal()}

                {renderTasksSection()}
              </Skeleton>
            </div>
          </Col>

          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className="h-full">
              <Link to="/destination-tracker/student-data">
                <div className="bg-white rounded-lg text-gray-500">
                  <div className="text-4xl p-4 font-thin">About Me...</div>
                  <div className="text-sm mt-2">
                    <img
                      alt="about me"
                      src={require("../../../../assets/images/aboutme.png")}
                    />
                  </div>
                </div>
              </Link>
              <Link to="/destination-tracker/september-guarantee">
                <div className="bg-white rounded-lg text-gray-500 mt-4">
                  <div className="text-4xl p-4 font-thin">My Choices...</div>
                  <div className="text-sm mt-2">
                    <img
                      alt="about me"
                      className="h-56 rounded-br-lg rounded-bl-lg object-cover w-full"
                      src={require("../../../../assets/icons/cards/my_choices.jpg")}
                    />
                  </div>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </HeaderWrapper>
  );
};

export default withContext(
  ([{ tasks, user }, dispatch]) => ({
    tasks,
    user,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  StudentActionPlan
);
