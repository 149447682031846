import React, { useEffect, useState } from "react";
import { PageMetaTags } from "Common";
import { withContext } from "Context";
import moment from "moment/moment";
import { getAdvisorsList } from "../../../../services/common";
import { Dropdown, Form, Input, Pagination, Row, Spin } from "antd";
import BookAppointmentModal from "../../Appointments/BookAppointmentModal";
import { Link, useHistory } from "react-router-dom";
import { ADVISER, MomentDateFormats, SUPER_ADMIN } from "../../../../constants";

const AdvisorSearch = ({ user }) => {
  const pageHeading = "Adviser Search";
  const [advisors, setAdvisors] = useState();
  const [schoolName, setSchoolName] = useState();
  const [studentName, setStudentName] = useState();
  const [special, setSpecial] = useState();
  const [loading, setLoading] = useState(false);
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [form] = Form.useForm();
  const history = useHistory();

  const getAdvisor = query => {
    setLoading(true);
    setSubHeading(query);
    getAdvisorsList({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: pageLimit,
        page: currentPage
      },
      query: query || {}
    })
      .then(res => {
        setLoading(false);
        setAdvisors(res);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getAdvisor();
  }, [currentPage, pageLimit]);

  const setSubHeading = query => {
    if (query && query.search) {
      setStudentName(query.search);
    } else {
      setStudentName("");
    }
    if (query && query.school) {
      setSchoolName(query.school);
    } else {
      setSchoolName("");
    }
    if (query && query.special) {
      setSpecial(query.special);
    } else {
      setSpecial("");
    }
  };

  const renderSubHeading = () => {
    if (!advisors) {
      return;
    }
    return (
      <h2 className="page-sub-heading">
        There are{" "}
        <span>
          " {(advisors && advisors.user_list && advisors.user_list.length) || 0}{" "}
          "
        </span>{" "}
        results{" "}
        {(schoolName || studentName || special) && (
          <React.Fragment>
            for <span>{schoolName || studentName || special}</span>
          </React.Fragment>
        )}
      </h2>
    );
  };

  const renderSchoolSearchFilter = () => {
    return (
      <div className="col-xl-3 col-md-12 col-lg-6">
        <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
          <label className="labelstyle">School Name</label>
          <Form.Item name="school">
            <Input
              type="text"
              placeholder="e.g Oakgrove"
              className="input-style"
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const navigateToAdviserProfile = advisor => {
    if (advisor && advisor.id) {
      history.push(`/adviser/${advisor.id}`);
    }
  };

  const renderDropdownOverlay = advisor => {
    return (
      <div className="border bg-white overlay">
        <div
          onClick={() => {
            setBookAppointmentModal(true);
            setSelectedAdvisor(advisor);
          }}
          className="dropdown-overlay-edit"
        >
          <i className="fas fa-pen" /> Book Appointment
        </div>
        <Link style={{ color: "black" }} to="/appointments">
          <div className="dropdown-overlay-edit">
            <i className="fas fa-list" /> View Appointments
          </div>
        </Link>
        <Link style={{ color: "black" }} to={`/adviser/${advisor.id}`}>
          <div className="dropdown-overlay-edit">
            <i className="fas fa-eye" /> View Profile
          </div>
        </Link>
      </div>
    );
  };
  const renderPagination = () => {
    if (!advisors) {
      return;
    }
    return (
      <Row className="mb-2 mr-2 mt-4" type="flex" justify="end">
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          showSizeChanger
          defaultPageSize={pageLimit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setPageLimit(p)}
          total={(advisors && advisors.total_count) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} tasks`
          }
        />
      </Row>
    );
  };

  return (
    <div className="content-panel">
      {bookAppointmentModal && (
        <BookAppointmentModal
          open={bookAppointmentModal}
          advisor={selectedAdvisor}
          userId={(selectedAdvisor && selectedAdvisor.id) || ""}
          setAdvisor={setSelectedAdvisor}
          onClose={setBookAppointmentModal}
          bookingFor="adviser"
        />
      )}
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>
      <Form
        data-cy="form-adviser-search"
        onFinish={values => getAdvisor(values)}
        form={form}
      >
        <div className="search-area">
          <div className="row">
            {user &&
              (user.role_slug === ADVISER || user.role_slug === SUPER_ADMIN) &&
              renderSchoolSearchFilter()}

            <div className="col-xl-3 col-md-12 col-lg-6">
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                <label className="labelstyle">Adviser Name</label>
                <Form.Item name="search">
                  <Input
                    data-cy="name"
                    type="text"
                    placeholder="e.g. Richard Burch"
                    className="input-style"
                  />
                </Form.Item>
              </div>
            </div>
            <div className=" col-xl-3 col-md-12 col-lg-6">
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                <label className="labelstyle">Specialisms</label>
                <Form.Item name="special">
                  <Input
                    data-cy="specialisms"
                    type="text"
                    placeholder="e.g. Design"
                    className="input-style"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-12 d-flex justify-content-end align-items-end">
              <span
                onClick={() => form.submit()}
                className="button-style button-style-green-btm button-style-normal-button"
              >
                Search
              </span>

              {/*Todo: Serves no purpose for bulk operations*/}
              {/*<a href="/"*/}
              {/*  className="button-style button-style-gray-btm button-style-normal-button">*/}
              {/*  Actions*/}
              {/*</a>*/}
            </div>
          </div>
        </div>

        {renderSubHeading()}

        <div className="search-wrapper">
          <div className="table-responsive mobile-spacing">
            <Spin spinning={loading}>
              <table style={{ minHeight: 30 }} className="table search-list">
                <tbody>
                  {((advisors && advisors.user_list) || []).map(advisor => (
                    <tr
                      key={`${advisor.id}-${advisor.school_name}`}
                      className="cursor-pointer"
                      onClick={() => navigateToAdviserProfile(advisor)}
                    >
                      <td>
                        <label className="check-style">
                          {advisor.name || ""}
                        </label>
                      </td>
                      <td>
                        {moment(advisor.registration_date).format(
                          MomentDateFormats.CustomDateFormat.dayMonthYear
                        )}
                      </td>
                      <td>{advisor.school_name || ""}</td>
                      <td>{advisor.specialism || ""}</td>
                      <td onClick={e => e.stopPropagation()}>
                        <div
                          className="chose-item dropleft"
                          data-toggle="dropdown"
                        >
                          <Dropdown
                            trigger={["click"]}
                            placement="bottomRight"
                            overlay={() => renderDropdownOverlay(advisor)}
                          >
                            <div
                              className="chose-item dropleft"
                              data-toggle="dropdown"
                            >
                              <i className="fas fa-ellipsis-h" />
                            </div>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {renderPagination()}
            </Spin>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), AdvisorSearch);
