import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, notification, Select, Skeleton } from "antd";
import { Option } from "antd/lib/mentions";
import { PageMetaTags } from "../../../../components/common";
import { formItems, TYPE } from "./formItemSchema";
import { getKS4Data, saveKS4Data } from "../../../../services/destination";
import { deepMerge, isAnyFieldEditable } from "../common";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";

function KS4Data({ user }) {
  const pageHeading =
    user && user.role_slug === STUDENT ? "Year 11" : "KS4 Data";
  const [ks4Data, setKs4Data] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  useEffect(() => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getKS4Data({ query: payload })
      .then(response => {
        if (response.success && response.status_code === 200) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setKs4Data(() => {
            setKs4Data(newData);
          });
        } else {
          setKs4Data(() => {
            setKs4Data(formItems);
          });
        }
      })
      .catch(error => {
        console.log(error);
        setKs4Data(() => {
          setKs4Data(formItems);
        });
      })
      .finally(() => setGetLoading(false));
  }, [query.get("id")]);

  const onFinish = formValues => {
    setLoading(true);
    if (query.get("id")) {
      // Note: `student_id` is required when GET/PUT from `adviser`
      formValues.student_id = query.get("id");
    }
    saveKS4Data({ body: formValues })
      .then(res => {
        notification.success({ message: `Saved the ${pageHeading}` });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to save ${pageHeading}`
        });
      })
      .finally(() => setLoading(false));
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              data-cy={item.name}
              defaultValue={item.value}
              readonly="true"
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !ks4Data["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              data-cy={item.name}
              defaultValue={item.value}
              disabled={!item.editable}
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSelectElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !ks4Data["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-4">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Select
              data-cy={item.name}
              showSearch
              className={"w-100"}
              allowClear={true}
              style={{ width: 200 }}
              placeholder={item.placeholder}
              optionFilterProp="children"
              disabled={!item.editable}
              defaultValue={item.value}
              onChange={onChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option disabled>Select</Option>
              {item.options &&
                item.options.map(option => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    );
  };

  return (
    <div data-cy="page-ks4-data" className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-ks4-data"
            form={form}
            onFinish={onFinish}
          >
            {ks4Data &&
              Object.keys(formItems).map(key => {
                if (ks4Data[key].type === TYPE.input && ks4Data[key].visibile) {
                  return renderInputElement(ks4Data[key]);
                } else if (
                  ks4Data[key].type === TYPE.select &&
                  ks4Data[key].visibile
                ) {
                  return renderSelectElement(ks4Data[key]);
                }
              })}

            {/*Submit*/}
            {editable && (
              <div className="col-12">
                <div className="form-group pb-4">
                  <input
                    type="submit"
                    className="button-style button-style-green-btm button-style-normal-button"
                    disabled={loading}
                    value={loading ? "Loading..." : "Submit"}
                  />
                </div>
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export { KS4Data };
