import React from "react";
import { Link } from "react-router-dom";
import { convertCypressIdPath } from "../../../common";

const DashboardLinks = ({ links }) => {
  if (!links) {
    return;
  }
  return (
    <React.Fragment>
      {links.map(link => (
        <div
          data-cy={`${convertCypressIdPath(link.path)}`}
          key={link.path}
          className="card-box"
        >
          <Link to={link.path}>
            <span>
              <i className={link.icon} />
            </span>
            <div className="holder">
              <h2 className="page-sub-heading">{link.name}</h2>
              <p>{link.desc}</p>
            </div>
          </Link>
        </div>
      ))}
    </React.Fragment>
  );
};

export default DashboardLinks;
