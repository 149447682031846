import { TYPE } from "../common";

const formItems = {
  form_visible: 1, // Default check to make it editable as fields rather label
  careers_interview_date_1: {
    name: "careers_interview_date_1",
    label: "Careers Interview date 1",
    editable: true,
    visibile: true,
    defaultValue: "",
    value: null,
    type: TYPE.date
  },
  careers_interview_date_2: {
    name: "careers_interview_date_2",
    label: "Careers Interview date 2",
    editable: true,
    visibile: true,
    defaultValue: "",
    value: null,
    type: TYPE.date
  },
  careers_interview_date_3: {
    name: "careers_interview_date_3",
    label: "Careers Interview date 3",
    editable: true,
    visibile: true,
    defaultValue: "",
    value: null,
    type: TYPE.date
  },
  learning_manager_or_send_notes: {
    name: "learning_manager_or_send_notes",
    label: "Learning manager/SEND notes",
    placeholder: "Input Learning manager/SEND notes",
    editable: false,
    visibile: false,
    value: null,
    type: TYPE.input
  },
  january_cenus_cohort_y_or_n: {
    name: "january_cenus_cohort_y_or_n",
    label: "January Census Cohort Y / N",
    editable: true,
    visibile: true,
    value: null,
    placeholder: "January Census Cohort Y / N",
    options: [
      {
        label: "Yes",
        value: "yes"
      },
      {
        label: "No",
        value: "no"
      }
    ],
    type: TYPE.select
  },
  careersInc_adviser: {
    name: "careersInc_adviser",
    label: "My Adviser",
    editable: false,
    visibile: false,
    value: null,
    placeholder: "Select Adviser",
    options: JSON.parse(localStorage.getItem("AdvisorsDropdown")),
    type: TYPE.select
  },
  live_rag: {
    name: "live_rag",
    label: "Live RAG / Progress",
    editable: true,
    visibile: true,
    value: null,
    placeholder: "Select RAG",
    options: [
      {
        label: "Positive destination confirmed",
        value: "1"
      },
      {
        label: "Positive destination identified",
        value: "2.1"
      },
      {
        label: "Destination(s) identified",
        value: "2.2"
      },
      {
        label: "No destination(s) identified",
        value: "2.3"
      },
      {
        label: "Potential NEET",
        value: "3"
      }
    ],
    type: TYPE.select
  },
  updated_he_intention: {
    name: "updated_he_intention",
    label: "IS UNI FOR ME?",
    editable: true,
    value: null,
    visibile: true,
    placeholder: "Select HE Intention",
    options: [
      {
        label: "Interested",
        value: "Interested"
      },
      {
        label: "Not Sure",
        value: "Not Sure"
      },
      {
        label: "Not Interested",
        value: "Not Interested"
      }
    ],
    type: TYPE.select
  },
  notes: {
    name: "notes",
    label: "notes",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.textArea
  }
};

export { formItems, TYPE };
