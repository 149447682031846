import React, { useEffect, useState } from "react";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { Col, Row } from "antd";
import { withContext } from "Context";
import { getAppointments } from "../../../../services/appointment";
import moment from "moment";
import BookAppointmentModal from "../BookAppointmentModal";
import AppointmentCalendar from "../AppointmentCalendar/AppointmentCalendar";

const StudentAppointments = ({ user }) => {
  const [events, setEvents] = useState();
  const [calendarView, setCalendarView] = useState(false);
  const [modal, setModal] = useState(false);

  const getAppointmentsList = query => {
    getAppointments({
      body: {
        sort_column: "id",
        page: 1,
        sort_order: "asc",
        page_limit: 100,
        filter_column: "student_id"
        // date_start: "2021-09-08T00:00:00Z",
        // date_end: moment.utc().format(),
      },
      query: query || {}
    })
      .then(response => setEvents(response.appointments))
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getAppointmentsList();
  }, []);

  return (
    <div>
      <HeaderWrapper
        title="My Appointments"
        image={require("../../../../assets/icons/header/icon-lists.png")}
      >
        {modal && (
          <BookAppointmentModal
            open={modal}
            onClose={() => setModal(false)}
            bookingFor="adviser"
            userId={user.adviser_id}
            advisor={{ id: user.adviser_id }}
          />
        )}
        <div>
          <Row gutter={[24, 24]} className="mb-4">
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <div className="bg-white rounded-lg h-full p-4 mb-4">
                <div className="flex text-gray-600 text-lg">
                  {user.adviser_id && (
                    <div
                      data-cy="button-book-appointment"
                      onClick={() => setModal(true)}
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={require("../../../../assets/icons/sidebar/AddApptIcon.svg")}
                        className="h-8 w-8"
                        alt="calendar"
                      />
                      <span className="pl-2"> Book Appointment</span>
                    </div>
                  )}
                  <div
                    onClick={() => setCalendarView(!calendarView)}
                    className="ml-8 flex items-center cursor-pointer"
                  >
                    <img
                      src={require("../../../../assets/icons/sidebar/CalViewIcon.svg")}
                      className="h-8 w-8"
                      alt="calendar"
                    />
                    <span className="pl-2">
                      {calendarView ? "Table" : "Calendar"} View
                    </span>
                  </div>
                </div>

                {calendarView ? (
                  <div className="border rounded-lg mt-6">
                    <AppointmentCalendar studentView />
                  </div>
                ) : (
                  <div className="flex flex-col border rounded-lg mt-6">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden border-gray-200 sm:rounded-lg">
                          <table className="min-w-full">
                            <thead className="">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider"
                                >
                                  Title
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider border-l"
                                >
                                  Type
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-2 text-left text-sm font-semibold text-gray-600 tracking-wider border-l"
                                >
                                  Date & Time
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-2 text-left text-sm font-medium text-gray-600 tracking-wider border-l"
                                >
                                  Total Time
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {events &&
                                Array.isArray(events) &&
                                events.map(event => (
                                  <tr
                                    data-cy={`appointment-${event.id}`}
                                    key={event.id}
                                  >
                                    <td className="px-6 py-2 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {event.title}
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap border-l">
                                      <div className="text-sm text-gray-900 capitalize">
                                        {event.appointment_type}
                                      </div>
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap border-l">
                                      <span className="text-sm text-gray-900">
                                        {moment(event.start).format("LLL")}
                                      </span>
                                    </td>
                                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 border-l">
                                      <span className="text-sm text-gray-900">
                                        {event.total_time}m
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <div className="h-full">
                <div className="bg-white rounded-lg text-gray-500">
                  <div className="text-4xl p-4 font-thin">About Me...</div>
                  <div className="text-sm mt-2">
                    <img
                      alt="about me"
                      className="h-auto rounded-br-lg rounded-bl-lg object-cover w-full"
                      src={require("../../../../assets/images/aboutme.png")}
                    />
                  </div>
                </div>
                <div className="bg-white rounded-lg text-gray-500 mt-4">
                  <div className="text-4xl p-4 font-thin">My Choices...</div>
                  <div className="text-sm mt-2">
                    <img
                      alt="about me"
                      className="h-56 rounded-br-lg rounded-bl-lg object-cover w-full"
                      src={require("../../../../assets/icons/cards/my_choices.jpg")}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </HeaderWrapper>
    </div>
  );
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  StudentAppointments
);
