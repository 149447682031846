import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, notification, Select, Skeleton, Switch } from "antd";
import { PageMetaTags } from "../../../../components/common";
import { Option } from "antd/lib/mentions";
import { formItems, TYPE } from "./formItemSchema";
import {
  getSeptemberGuarantee,
  saveSeptemberGuarantee,
  getDestinationDropdown
} from "../../../../services/destination";
import { deepMerge, isAnyFieldEditable } from "../common";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";

function SeptemberGuarantee({ user }) {
  const pageHeading =
    user && user.role_slug === STUDENT ? "My Choices" : "September Guarantee";
  const [septemberGuaranteeData, setSeptemberGuaranteeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group
  const [destinationDrop, setDestinationDrop] = useState([]);
  const [sectorsDrop, setSectorsDrop] = useState([]);
  const [levelDrop, setLevelDrop] = useState([]);

  useEffect(() => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
      payload.year_group = query.get("year_group");
    }
    //for dynamic destination dynamic dropdown
    getDestinationDropdown({ query: payload })
      .then(response => {
        setDestinationDrop(response.destinationList);
        setSectorsDrop(response.sectorsList);
        setLevelDrop(response.levelList);
      })
      .catch(error => console.log(error));
    getSeptemberGuarantee({ query: payload })
      .then(response => {
        if (response.success && response.status_code === 200) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setSeptemberGuaranteeData(() => {
            setSeptemberGuaranteeData(newData);
          });
        } else {
          setSeptemberGuaranteeData(() => {
            setSeptemberGuaranteeData(formItems);
          });
        }
      })
      .catch(error => {
        console.log(error);
        setSeptemberGuaranteeData(() => {
          setSeptemberGuaranteeData(formItems);
        });
      })
      .finally(() => setGetLoading(false));
  }, [query.get("id")]);

  const onFinish = formValues => {
    setLoading(true);
    if (query.get("id")) {
      // Note: `student_id` is required when GET/PUT from `adviser`
      formValues.student_id = query.get("id");
    }
    saveSeptemberGuarantee({ body: formValues })
      .then(res => {
        notification.success({ message: `Saved the ${pageHeading}` });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to save ${pageHeading}`
        });
      })
      .finally(() => setLoading(false));
  };

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name} key={item.name}>
            <Input
              data-cy={item.name}
              defaultValue={item.value}
              readonly="true"
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !septemberGuaranteeData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name} key={item.name}>
            <Input
              data-cy={item.name}
              defaultValue={item.value}
              disabled={!item.editable}
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSwitchElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !septemberGuaranteeData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name} valuePropName="checked" key={item.name}>
            {item.value == "Y" ? (
              <Switch
                data-cy={item.name}
                defaultChecked={item.value}
                checkedChildren={item.checkedChildren}
                unCheckedChildren={item.unCheckedChildren}
              />
            ) : (
              <Switch
                data-cy={item.name}
                checkedChildren={item.checkedChildren}
                unCheckedChildren={item.unCheckedChildren}
              />
            )}
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSelectElement = item => {
    if (item.name == "destination") {
      item.options = destinationDrop;
    }
    if (item.name == "level") {
      item.options = levelDrop;
    }
    if (item.name == "sector") {
      item.options = sectorsDrop;
    }

    //debugger;
    if (!item) {
      return;
    }
    if (!item.editable || !septemberGuaranteeData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-4">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name} key={item.name}>
            <Select
              data-cy={item.name}
              showSearch
              className={"w-100"}
              allowClear={true}
              style={{ width: 200 }}
              placeholder={item.placeholder}
              optionFilterProp="children"
              disabled={!item.editable}
              defaultValue={item.value}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option disabled>Select</Option>
              {item.options &&
                item.options.map(option => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSubHeadingText = () => {
    return (
      <div className="mb-4 ml-2 w-75">
        <p>
          This information is based on your conversation with your careers
          adviser and the course we think you are going to do. Speak to your
          careers adviser or careers lead at school if you have changed your
          mind or don’t agree with what is shown on this page.
        </p>
      </div>
    );
  };

  return (
    <div data-cy="page-september-guarantee" className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}

      {user && user.role_slug === STUDENT && renderSubHeadingText()}

      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-september-guarantee"
            form={form}
            onFinish={onFinish}
          >
            <div className="row">
              {septemberGuaranteeData &&
                Object.keys(formItems).map(key => {
                  if (
                    septemberGuaranteeData[key].type === TYPE.input &&
                    septemberGuaranteeData[key].visibile
                  ) {
                    return renderInputElement(septemberGuaranteeData[key]);
                  } else if (
                    septemberGuaranteeData[key].type === TYPE.switch &&
                    septemberGuaranteeData[key].visibile
                  ) {
                    return renderSwitchElement(septemberGuaranteeData[key]);
                  } else if (
                    septemberGuaranteeData[key].type === TYPE.select &&
                    septemberGuaranteeData[key].visibile
                  ) {
                    return renderSelectElement(septemberGuaranteeData[key]);
                  }
                })}

              {/*Submit*/}
              {editable && (
                <div className="col-12">
                  <div className="form-group pb-4">
                    <input
                      type="submit"
                      className="button-style button-style-green-btm button-style-normal-button"
                      disabled={loading}
                      value={loading ? "Loading..." : "Submit"}
                    />
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export { SeptemberGuarantee };
