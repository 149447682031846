import React, { useEffect, useState } from "react";
import { Form, Input, notification, Radio, Skeleton, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { PageMetaTags } from "Common";
import {
  getMayReturn,
  saveMayReturn,
  getDestinationDropdown
} from "../../../../services/destination";
import { useHistory } from "react-router";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";
const formItems = [
  {
    name: "intended_course",
    label: "Intended Course"
  },
  {
    name: "intended_provider",
    label: "Intended Provider"
  }
];

const selectItems = [
  {
    name: "intended_destination",
    label: "Intended Destination"
  },
  {
    name: "level",
    label: "Level"
  },
  {
    name: "sector",
    label: "Sector"
  }
];

const moreFields = [
  { name: "elephant_group", label: "Elephant Group" },
  { name: "russell_group", label: "Russell Group" }
];

const attendings = [
  { id: 1, name: "Yes" },
  { id: 0, name: "NO" }
];

const MayReturn = ({ user }) => {
  const pageHeading = "Post-16 Live Dashboard";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group
  const [destinationDrop, setDestinationDrop] = useState([]);
  const [sectorsDrop, setSectorsDrop] = useState([]);
  const [levelDrop, setLevelDrop] = useState([]);

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
      payload.year_group = query.get("year_group");
    }
    //for dynamic destination dynamic dropdown
    getDestinationDropdown({ query: payload })
      .then(response => {
        setDestinationDrop(response.destinationList);
        setSectorsDrop(response.sectorsList);
        setLevelDrop(response.levelList);
      })
      .catch(error => console.log(error));
    getMayReturn({ query: payload })
      .then(res => {
        if (res.success) {
          setFormData(res.data || undefined);
          setEditable(res.data && res.data.form_visible === 0 ? false : true);
          const values = Object.keys(res.data).reduce(
            (prev, k) => ({ ...prev, [k]: res.data[k].value }),
            {}
          );
          form.setFieldsValue({ ...values });
          setGetLoading(false);
        }
      })
      .catch(() => setGetLoading(false));
  };

  useEffect(() => {
    getDetails();
  }, [query.get("id")]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    saveMayReturn({ body: values })
      .then(res => {
        setLoading(false);
        getDetails();
        notification.success({ message: "Saved the May Return" });
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: "Failed to save May Return"
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}
      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}{" "}
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-may-return-live-dashboard"
            form={form}
            onFinish={onFinish}
          >
            <div className="row">
              {formData &&
                formItems
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div key={name} className="col-12 col-md-6">
                      <div className="form-group pb-2">
                        <label className="labelstyle">{label}</label>
                        <Form.Item name={name}>
                          {editable ? (
                            <Input
                              data-cy={name}
                              disabled={
                                formData && formData[name]
                                  ? !formData[name].editable
                                  : false
                              }
                            />
                          ) : (
                            <div className="label-desc">
                              <Input
                                defaultValue={formData[name].value}
                                readonly="true"
                              />
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  ))}
              {formData &&
                moreFields
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div key={name} className="col-12 col-md-6 form-group pb-2">
                      <label className="labelstyle">{label}</label>
                      <Form.Item name={name}>
                        {editable ? (
                          <Radio.Group
                            data-cy={name}
                            disabled={
                              formData && formData[name]
                                ? !formData[name].editable
                                : false
                            }
                          >
                            {attendings.map(({ id, name }) => (
                              <Radio value={id}>{name}</Radio>
                            ))}
                          </Radio.Group>
                        ) : (
                          <div className="label-desc">
                            {formData && formData[name].value === 1
                              ? "Yes"
                              : "No"}
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  ))}

              {formData &&
                selectItems
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div key={name} className="col-12 col-md-6 form-group pb-2">
                      <label className="labelstyle">{label}</label>
                      <Form.Item name={name} key={name}>
                        {editable ? (
                          <Form.Item name={name} key={name}>
                            <Select
                              data-cy={name}
                              showSearch
                              className={"w-100"}
                              allowClear={true}
                              style={{
                                width: 200
                              }}
                              optionFilterProp="children"
                              disabled={!formData[name].editable}
                              defaultValue={formData[name].value}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option disabled>Select</Option>
                              {name == "intended_destination" &&
                                destinationDrop &&
                                destinationDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              {name == "sector" &&
                                sectorsDrop &&
                                sectorsDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              {name == "level" &&
                                levelDrop &&
                                levelDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <div className="label-desc">
                            <Select
                              data-cy={name}
                              showSearch
                              className={"w-100"}
                              allowClear={true}
                              style={{
                                width: 200
                              }}
                              optionFilterProp="children"
                              disabled={!formData[name].editable}
                              defaultValue={formData[name].value}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option disabled>Select</Option>
                              {name == "intended_destination" &&
                                destinationDrop &&
                                destinationDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              {name == "sector" &&
                                sectorsDrop &&
                                sectorsDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                              {name == "level" &&
                                levelDrop &&
                                levelDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  ))}
            </div>
            {editable && (
              <div className="form-group d-flex justify-content-end">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default MayReturn;
