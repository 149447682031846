import React from "react";
import { Breadcrumbs, PageMetaTags } from "../../../components/common";

const renderBreadcrumbs = () => {
  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          name: "Resources",
          path: `/resources`
        },
        {
          name: "LMI Infomation for 38 LEP Region",
          path: null
        }
      ]}
    />
  );
};

const WebReport = () => {
  return (
    <div data-cy="emsi-web-report" style={{ height: "90vh", width: "100%" }}>
      <PageMetaTags title="Paid Tier - LMI Infomation for 38 LEP Region - EMSI Datasets - Wage Overview" />
      {renderBreadcrumbs()}
      <h1>
        Paid Tier - LMI Infomation for 38 LEP Region - EMSI Datasets - Wage
        Overview
      </h1>
      <iframe
        title="Paid Tier - LMI Infomation for 38 LEP Region - EMSI Datasets - Wage Overview"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiNmI4MjI3NjQtYjRlZC00YzMxLWJmODAtYzJkZTZlYmVlOGIzIiwidCI6ImNlOWQwYzY3LTRlNmQtNDNhYS05NWFiLWVjOTQ2MGM1YjU3MCJ9"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default WebReport;
