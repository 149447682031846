import React, { useEffect, useState } from "react";
import { Form, Input, notification, Skeleton, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { PageMetaTags } from "Common";
import {
  getks5Studies,
  saveks5Studies,
  getDestinationDropdown
} from "../../../../services/destination";
import { useHistory } from "react-router";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";

const formItems = [
  { name: "subject_1", label: "Subject 1" },
  { name: "subject_2", label: "Subject 2" },
  { name: "subject_3", label: "Subject 3" },
  { name: "subject_4", label: "Subject 4" }
];

const selectItems = [
  {
    name: "level_of_post_16_study",
    label: "Level of post 16 study"
  }
];

const Ks5Studies = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group
  const [levelDrop, setLevelDrop] = useState([]);

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
      payload.year_group = query.get("year_group");
    }
    //for dynamic destination dynamic dropdown
    getDestinationDropdown({ query: payload })
      .then(response => {
        setLevelDrop(response.levelPostStudyList);
      })
      .catch(error => console.log(error));
    getks5Studies({ query: payload })
      .then(res => {
        if (res) {
          setFormData(res.data || undefined);
          setEditable(
            res.data && Number(res.data.form_visible) === 0 ? false : true
          );
          const values = Object.keys(res.data).reduce(
            (prev, k) => ({ ...prev, [k]: res.data[k].value }),
            {}
          );
          setGetLoading(false);
          form.setFieldsValue({ ...values });
        }
      })
      .catch(() => setGetLoading(false));
  };

  useEffect(() => {
    getDetails();
  }, [query.get("id")]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    saveks5Studies({ body: values })
      .then(() => {
        setLoading(false);
        getDetails();
        notification.success({ message: "Saved the KS5 Studies" });
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: "Failed to save the KS5 Studies"
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      <PageMetaTags title="KS5 Studies" />
      <h1 className="page-heading">KS5 Studies</h1>
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-ks5-studies"
            form={form}
            onFinish={onFinish}
          >
            <div className="row">
              {formData &&
                formItems
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div className="col-12 col-md-6">
                      <div className="form-group pb-2">
                        <label className="labelstyle">{label}</label>
                        <Form.Item name={name}>
                          {editable ? (
                            <Input
                              data-cy={name}
                              disabled={
                                formData && formData[name]
                                  ? !formData[name].editable
                                  : false
                              }
                            />
                          ) : (
                            <div className="label-desc">
                              {formData[name].value}
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  ))}
              {formData &&
                selectItems
                  .filter(
                    ({ name }) => formData[name] && formData[name].visibile
                  )
                  .map(({ name, label }) => (
                    <div key={name} className="col-12 col-md-6 form-group pb-2">
                      <label className="labelstyle">{label}</label>
                      <Form.Item name={name} key={name}>
                        {editable ? (
                          <Form.Item name={name} key={name}>
                            <Select
                              data-cy={name}
                              showSearch
                              className={"w-100"}
                              allowClear={true}
                              style={{
                                width: 200
                              }}
                              optionFilterProp="children"
                              disabled={!formData[name].editable}
                              defaultValue={formData[name].value}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option disabled>Select</Option>
                              {levelDrop &&
                                levelDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          <div className="label-desc">
                            <Select
                              data-cy={name}
                              showSearch
                              className={"w-100"}
                              allowClear={true}
                              style={{
                                width: 200
                              }}
                              optionFilterProp="children"
                              disabled={!formData[name].editable}
                              defaultValue={formData[name].value}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option disabled>Select</Option>
                              {levelDrop &&
                                levelDrop.map(option => (
                                  <Option value={option.value}>
                                    {option.label}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        )}
                      </Form.Item>
                    </div>
                  ))}
            </div>
            {editable && (
              <div className="form-group d-flex justify-content-end">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default Ks5Studies;
