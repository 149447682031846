import React from "react";
import { Link } from "react-router-dom";
import { Tooltip, Form, Input, notification } from "antd";
import { inlineUpdate } from "../../../../services/destination";

const columnNames = {
  informationNotesColumn: "informationNotesColumn",
  ks4Column: "ks4Column",
  ks4QuestionnaireColumn: "ks4QuestionnaireColumn",
  ks5StudiesColumn: "ks5StudiesColumn",
  mayReturnColumn: "mayReturnColumn",
  octoberActivityColumn: "octoberActivityColumn",
  post16QuestionnaireColumn: "post16QuestionnaireColumn",
  septemberGuaranteeColumn: "septemberGuaranteeColumn",
  studentDataColumn: "studentDataColumn",
  subjectOptionColumn: "subjectOptionColumn",
  y11DashboardColumn: "y11DashboardColumn"
};

const dataSets = {
  InformationAndNotes: {
    title: "Information and Notes",
    column: columnNames.informationNotesColumn
  },
  KS4Data: {
    title: "KS4 Data",
    column: columnNames.ks4Column
  },
  KS4Questionnaire: {
    title: "KS4 Questionnaire",
    column: columnNames.ks4QuestionnaireColumn
  },
  Ks5Studies: {
    title: "KS5 Studies",
    column: columnNames.ks5StudiesColumn
  },
  MayReturnLiveDashboard: {
    title: "Post-16 Live Dashboard",
    column: columnNames.mayReturnColumn
  },
  OctoberActivitySurvey: {
    title: "October Activity Survey",
    column: columnNames.octoberActivityColumn
  },
  SeptemberGuarantee: {
    title: "September Guarantee",
    column: columnNames.septemberGuaranteeColumn
  },
  StudentData: {
    title: "Student Data",
    column: columnNames.studentDataColumn
  },
  SubjectsOptionsUCAS: {
    title: "Subjects options ucas",
    column: columnNames.subjectOptionColumn
  },
  Post16Questionnaire: {
    title: "Post 16 Questionnaire",
    column: columnNames.post16QuestionnaireColumn
  },
  Y11Dashboard: {
    title: "Y11 Dashboard",
    column: columnNames.y11DashboardColumn
  }
};

const dataSetsReverseColumnMapping = {
  [columnNames.informationNotesColumn]: {
    title: "Information and Notes"
  },
  [columnNames.ks4Column]: {
    title: "KS4 Data"
  },
  [columnNames.ks4QuestionnaireColumn]: {
    title: "KS4 Questionnaire"
  },
  [columnNames.ks5StudiesColumn]: {
    title: "KS5 Studies"
  },
  [columnNames.mayReturnColumn]: {
    title: "Post-16 Live Dashboard"
  },
  [columnNames.octoberActivityColumn]: {
    title: "October Activity Survey"
  },
  [columnNames.septemberGuaranteeColumn]: {
    title: "September Guarantee"
  },
  [columnNames.studentDataColumn]: {
    title: "Student Data"
  },
  [columnNames.subjectOptionColumn]: {
    title: "Subjects options ucas"
  },
  [columnNames.post16QuestionnaireColumn]: {
    title: "Post 16 Questionnaire"
  },
  [columnNames.y11DashboardColumn]: {
    title: "Y11 Dashboard"
  }
};

const renderEmailTitle = (value, record) => {
  return (
    <span>
      <i className={"fa fa-envelope mr-2"} />
    </span>
  );
};

const renderEmailContent = (value, record) => {
  return (
    <Link to={`emails/new?id=${record.id}`}>
      <span>
        <i className={"fa fa-envelope mr-2"} />
      </span>
    </Link>
  );
};

const renderNameContent = (value, record) => {
  return (
    <Link
      to={`/students/${record.id}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={event => {
        event.stopPropagation(); // prevent event to propogate to parent to have row click which is default functionality
      }}
    >
      {value}
    </Link>
  );
};

const fixedColumnsMapping = columns => {
  const keys = Object.keys(columns);
  return keys.map(key => {
    if (columns[key].key === "name") {
      return {
        title:
          columns[key].key === "email" ? renderEmailTitle : columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        className: "fixed-heading",
        width: columns[key].key === "email" ? 30 : 90,
        fixed: "left",
        sorter: (a, b) => a.name - b.name,
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        render: (value, record) => {
          if (columns[key].key === "email") {
            return renderEmailContent(value, record);
          } else if (columns[key].key === "name") {
            return renderNameContent(value, record);
          } else if (columns[key].key === "school_name") {
            return renderNameContent(value, record);
          } else {
            return value;
          }
        }
      };
    } else {
      return {
        title:
          columns[key].key === "email" ? renderEmailTitle : columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        className: "fixed-heading",
        width: columns[key].key === "email" ? 30 : 90,
        fixed: "left",
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        render: (value, record) => {
          if (columns[key].key === "email") {
            return renderEmailContent(value, record);
          } else if (columns[key].key === "name") {
            return renderNameContent(value, record);
          } else if (columns[key].key === "school_name") {
            return renderNameContent(value, record);
          } else {
            return value;
          }
        }
      };
    }
  });
};

/*click editable stuff start here */

const save = (val, record, id, key) => {
  const el1 = document.querySelector("#" + record);
  const el3 = document.getElementById(record + "_tool");

  let formValues = {};
  formValues.student_id = id;
  formValues.key = key;
  if (el1.value) {
    formValues.value = el1.value;
  } else {
    formValues.value = null;
  }

  //validate data
  const GenderValues = [null, "Male", "Female"];
  const YearGroupValues = [
    "7",
    "8",
    "9",
    "10",
    "11",
    "11 L",
    "12",
    "13",
    "13 L"
  ];
  const dropYesNoValues = [null, "Y", "N"];
  if (key === "gender") {
    const checkGenderValid = GenderValues.includes(formValues.value);
    if (checkGenderValid === false) {
      notification.error({ message: `Allowed values :  Male OR Female` });
      return false;
    }
    el3.innerText = formValues.value;
  }
  if (key === "year_group_stud") {
    const checkYearValid = YearGroupValues.includes(formValues.value);
    if (checkYearValid === false) {
      notification.error({
        message: `Allowed values : 7, 8, 9, 10, 11, 12, 13`
      });
      return false;
    }
  }
  if (key === "sen_status_stud") {
    const checkSenValid = dropYesNoValues.includes(formValues.value);
    if (checkSenValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "russell_group_oct") {
    const checkSenValid = dropYesNoValues.includes(formValues.value);
    if (checkSenValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "elephant_group_oct") {
    const checkSenValid = dropYesNoValues.includes(formValues.value);
    if (checkSenValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "attending") {
    const attendingYesNoValues = ["Yes", "No"];
    const checkAttendingValid = attendingYesNoValues.includes(formValues.value);
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkAttendingValid === false) {
      notification.error({ message: `Allowed values : Yes OR No` });
      return false;
    }
    el3.innerText = formValues.value ? formValues.value : "NULL";
  }
  if (key === "fsm_stud") {
    const checkFsmValid = dropYesNoValues.includes(formValues.value);
    if (checkFsmValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "looked_after_stud") {
    const checkLookingValid = dropYesNoValues.includes(formValues.value);
    if (checkLookingValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "pupil_premium_stud") {
    const checkpupil_premiumValid = dropYesNoValues.includes(formValues.value);
    if (checkpupil_premiumValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "eal_stud") {
    const checkEalValid = dropYesNoValues.includes(formValues.value);
    if (checkEalValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "ks4_average_score") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (
      /^[+-]?[0-9]+([.][0-9]+)?([eE][+-]?[0-9]+)?$/.test(formValues.value) &&
      formValues.value > -1 &&
      formValues.value <= 9
    ) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    } else {
      notification.error({ message: `Please enter a floating number.` });
      return false;
    }
  }
  // KS4 Data validate
  if (key === "ks4_aps") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (
      /^([1-9]{0,1})(\.[0-9])?$/.test(formValues.value) &&
      formValues.value > -1 &&
      formValues.value <= 9
    ) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    } else {
      notification.error({ message: `Please enter a number between 0 and 9` });
      return false;
    }
  }
  if (key === "gcse_english") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (
      /^\d+$/.test(formValues.value) &&
      formValues.value > -1 &&
      formValues.value <= 9
    ) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    } else {
      notification.error({ message: `Please enter a number between 0 and 9` });
      return false;
    }
  }
  if (key === "gcse_maths") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (
      /^\d+$/.test(formValues.value) &&
      formValues.value > -1 &&
      formValues.value <= 9
    ) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    } else {
      notification.error({ message: `Please enter a number between 0 and 9` });
      return false;
    }
  }
  if (key === "gcse_science") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (
      /^\d+$/.test(formValues.value) &&
      formValues.value > -1 &&
      formValues.value <= 9
    ) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    } else {
      notification.error({ message: `Please enter a number between 0 and 9` });
      return false;
    }
  }

  if (
    key === "expected_level" ||
    key === "level_sept" ||
    key === "level_oct" ||
    key === "intended_level_y11"
  ) {
    if (seletedYear > 11) {
      const expectedLevelArr = Object.keys(sectorslevelMayArr);
      const checkexpectedLevelValid = expectedLevelArr.includes(
        formValues.value
      );
      if (formValues.value === null) {
        //el3.innerText= "NULL";
      } else if (checkexpectedLevelValid === false) {
        notification.error({
          message: `Invalid code, Allowed code : ` + expectedLevelArr
        });
        return false;
      } else {
        Object.keys(sectorslevelMayArr).forEach(function(k) {
          if (k === formValues.value) {
            if (typeof el3 === "undefined" || el3 === null) {
              console.log("✅ Element is empty");
            } else {
              el3.innerText = sectorslevelMayArr[formValues.value];
            }
            formValues.value = formValues.value;
          }
        });
      }
    } else {
      const expectedLevelArr = [null, "1", "2", "3", "ENT"];
      const checkexpectedLevelValid = expectedLevelArr.includes(
        formValues.value
      );

      if (formValues.value === null) {
        //el3.innerText="NULL";
      } else if (checkexpectedLevelValid === false) {
        notification.error({
          message: `Invalid code, Allowed code : ` + expectedLevelArr
        });
        return false;
      } else {
        Object.keys(levelArr).forEach(function(k) {
          if (k === formValues.value) {
            if (typeof el3 === "undefined" || el3 === null) {
              console.log("✅ Element is empty");
            } else {
              el3.innerText = levelArr[formValues.value]
                ? levelArr[formValues.value]
                : "NULL";
            }
          }
        });
      }
    }
  }
  // level_of_post_16_study
  if (key === "level_of_post_16_study") {
    const expectedLevelArr = Object.keys(levelPostStudyArr);
    const checkexpectedLevelValid = expectedLevelArr.includes(formValues.value);
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkexpectedLevelValid === false) {
      notification.error({
        message: `Invalid code, Allowed code : ` + expectedLevelArr
      });
      return false;
    }
    Object.keys(levelPostStudyArr).forEach(function(k) {
      if (k === formValues.value) {
        if (typeof el3 === "undefined" || el3 === null) {
          console.log("✅ Element is empty");
        } else {
          el3.innerText = levelPostStudyArr[formValues.value];
        }
        formValues.value = formValues.value;
      }
    });
  }
  //KS4 Questionnaire

  if (
    key === "intended_destination_ks4" ||
    key === "destination_sept" ||
    key === "destination_oct" ||
    key === "intended_destination_y11"
  ) {
    if (seletedYear > 11) {
      const intendedDestinationArr = Object.keys(destinationMayArr);
      const checkintendedDestinationValid = intendedDestinationArr.includes(
        formValues.value
      );
      if (formValues.value === null) {
        el3.innerText = "NULL";
      } else if (checkintendedDestinationValid === false) {
        notification.error({
          message: `Invalid code, Allowed code : ` + intendedDestinationArr
        });
        return false;
      }
      Object.keys(destinationMayArr).forEach(function(k) {
        if (k === formValues.value) {
          formValues.value = destinationMayArr[k];
          el3.innerText = destinationMayArr[k];
        }
      });
    } else {
      const intendedDestinationArr = Object.keys(destinationArr);
      const checkintendedDestinationValid = intendedDestinationArr.includes(
        formValues.value
      );
      if (formValues.value === null) {
        el3.innerText = "NULL";
      } else if (checkintendedDestinationValid === false) {
        notification.error({
          message: `Invalid code, Allowed code : ` + intendedDestinationArr
        });
        return false;
      }
      Object.keys(destinationArr).forEach(function(k) {
        if (k === formValues.value) {
          formValues.value = destinationArr[k];
          el3.innerText = destinationArr[k];
        }
      });
    }
  }

  //intended_destination_may
  if (key === "intended_destination_may" || key === "intended_destination_16") {
    const intendeddestinationPostQuestionnaireArr = Object.keys(
      destinationPostQuestionnaireArr
    );
    const checkintendedDestinationValid = intendeddestinationPostQuestionnaireArr.includes(
      formValues.value
    );
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkintendedDestinationValid === false) {
      notification.error({
        message:
          `Invalid code, Allowed code : ` +
          intendeddestinationPostQuestionnaireArr
      });
      return false;
    }
    Object.keys(destinationPostQuestionnaireArr).forEach(function(k) {
      if (k === formValues.value) {
        formValues.value = destinationPostQuestionnaireArr[k];
        el3.innerText = destinationPostQuestionnaireArr[k];
      }
    });
  }
  //sector_may
  if (key === "sector_may" || key === "sector_16") {
    const sector_mayArr = Object.keys(sectorsPostQuestionnaireArr);
    const checkintendedDestinationValid = sector_mayArr.includes(
      formValues.value
    );
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkintendedDestinationValid === false) {
      notification.error({
        message: `Invalid code, Allowed code : ` + sector_mayArr
      });
      return false;
    }
    Object.keys(sectorsPostQuestionnaireArr).forEach(function(k) {
      if (k === formValues.value) {
        formValues.value = sectorsPostQuestionnaireArr[k];
        el3.innerText = sectorsPostQuestionnaireArr[k];
      }
    });
  }

  const RagForPriorityArr = [null, "1", "2.1", "2.2", "2.3", "3"];
  if (key === "rag_for_priority_ks4" || key === "rag_for_priority_16") {
    const checkRagForPriorityK4Valid = RagForPriorityArr.includes(
      formValues.value
    );
    if (checkRagForPriorityK4Valid === false) {
      notification.error({ message: `Allowed values : 1, 2.1, 2.2, 2.3, 3` });
      return false;
    }
  }

  if (
    key === "sector_ks4" ||
    key === "sector_y11" ||
    key === "sector_oct" ||
    key === "sector_sept"
  ) {
    if (seletedYear > 11) {
      const sectorks4Arr = Object.keys(sectorsMayArr);
      const checksectorks4ArrValid = sectorks4Arr.includes(formValues.value);
      if (formValues.value === null) {
        el3.innerText = "NULL";
      } else if (checksectorks4ArrValid === false) {
        notification.error({
          message: `Invalid code, Allowed code :` + sectorks4Arr
        });
        return false;
      }
      Object.keys(sectorsMayArr).forEach(function(k) {
        if (k === formValues.value) {
          formValues.value = sectorsMayArr[k];
          el3.innerText = sectorsMayArr[k];
        }
      });
    } else {
      const sectorks4Arr = Object.keys(sectorsArr);
      const checksectorks4ArrValid = sectorks4Arr.includes(formValues.value);
      if (formValues.value === null) {
        el3.innerText = "NULL";
      } else if (checksectorks4ArrValid === false) {
        notification.error({
          message: `Invalid code, Allowed code :` + sectorks4Arr
        });
        return false;
      }
      Object.keys(sectorsArr).forEach(function(k) {
        if (k === formValues.value) {
          formValues.value = sectorsArr[k];
          el3.innerText = sectorsArr[k];
        }
      });
    }
  }

  //const heintentionks4Arr = ["Interested", "Not Sure", "Not at all interested"];
  const heintentionks4Arr = Object.keys(heintentionks);
  if (key === "he_intention_ks4" || key === "he_intention_y11") {
    const checkheintentionks4ArrValid = heintentionks4Arr.includes(
      formValues.value
    );
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkheintentionks4ArrValid === false) {
      notification.error({
        message: `Invalid code, Allowed code :` + heintentionks4Arr
      });
      return false;
    }
    Object.keys(heintentionks).forEach(function(k) {
      if (k === formValues.value) {
        formValues.value = heintentionks[k];
        el3.innerText = heintentionks[k];
      }
    });
  }

  //Information and Notes
  if (key === "january_cenus_cohort_y_or_n") {
    const checkjanuary_cenus_cohortValid = dropYesNoValues.includes(
      formValues.value
    );
    if (checkjanuary_cenus_cohortValid === false) {
      notification.error({
        message: `Please use upper case only. Allowed values : Y OR N`
      });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }
  if (key === "updated_he_intention" || key === "he_intention_16") {
    const checkupdated_he_intentionValid = heintentionks4Arr.includes(
      formValues.value
    );
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (checkupdated_he_intentionValid === false) {
      notification.error({
        message: `Invalid code, Allowed code :` + heintentionks4Arr
      });
      return false;
    }
    Object.keys(heintentionks).forEach(function(k) {
      if (k === formValues.value) {
        formValues.value = heintentionks[k];
        el3.innerText = heintentionks[k];
      }
    });
  }
  if (key === "applied_y11") {
    if (formValues.value) {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }

  if (key === "live_rag") {
    const checkRagForPrioritylive_ragValid = RagForPriorityArr.includes(
      formValues.value
    );
    if (checkRagForPrioritylive_ragValid === false) {
      notification.error({ message: `Allowed values : 1, 2.1, 2.2, 2.3, 3` });
      return false;
    } else {
      el3.innerText = formValues.value ? formValues.value : "NULL";
    }
  }

  const re = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/g;
  if (key === "careers_interview_date_1") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (!formValues.value.match(re)) {
      notification.error({ message: `Please enter valid date: dd/mm/yyyy` });
      return false;
    } else {
      el3.innerText = formValues.value;
    }
  }
  if (key === "careers_interview_date_2") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (!formValues.value.match(re)) {
      notification.error({ message: `Please enter valid date: dd/mm/yyyy` });
      return false;
    } else {
      el3.innerText = formValues.value;
    }
  }
  if (key === "careers_interview_date_3") {
    if (formValues.value === null) {
      el3.innerText = "NULL";
    } else if (!formValues.value.match(re)) {
      notification.error({ message: `Please enter valid date: dd/mm/yyyy` });
      return false;
    } else {
      el3.innerText = formValues.value;
    }
  }
  if (
    key === "offer_made_sept" ||
    key === "russell_group_sept" ||
    key === "elephant_group_sept"
  ) {
    const septError = [null, "Y", "N"];
    const checkRagForPriorityK4Valid = septError.includes(formValues.value);
    if (checkRagForPriorityK4Valid === false) {
      notification.error({ message: `Allowed values : Y, N` });
      return false;
    }
    const val = findKey(septOnOffArr, formValues.value);
    el3.innerText = val ? val : "NULL";
  }

  inlineUpdate({ body: formValues })
    .then(res => {
      notification.success({ message: `Saved.` });
    })
    .catch(error => {
      console.log(error);
      notification.error({ message: `Failed to save ${error}` });
    });
};
const spetProviderArr = () => {
  if (
    localStorage.getItem("september_provider") !== null ||
    JSON.parse(localStorage.getItem("september_provider"))?.length > 0
  ) {
    return JSON.parse(localStorage.getItem("september_provider"));
  } else {
    return [
      {
        text: "blank",
        value: "NULL"
      }
    ];
  }
};

const octProviderArr = () => {
  if (
    localStorage.getItem("october_provider") !== null ||
    JSON.parse(localStorage.getItem("october_provider"))?.length > 0
  ) {
    return JSON.parse(localStorage.getItem("october_provider"));
  } else {
    return [
      {
        text: "blank",
        value: "NULL"
      }
    ];
  }
};

/* end here */
const filterArr = columnNames => {
  if (
    columnNames === "live_rag" ||
    columnNames === "rag_for_priority_16" ||
    columnNames === "rag_for_priority_ks4"
  ) {
    return [
      {
        text: "1",
        value: "1"
      },
      {
        text: "2.1",
        value: "2.1"
      },
      {
        text: "2.2",
        value: "2.2"
      },
      {
        text: "2.3",
        value: "2.3"
      },
      {
        text: "3",
        value: "3"
      },
      {
        text: "blank",
        value: "NULL"
      }
    ];
  } else if (
    columnNames === "careers_interview_date_1" ||
    columnNames === "careers_interview_date_2" ||
    columnNames === "careers_interview_date_3"
  ) {
    return [
      {
        text: "2020",
        value: "2020"
      },
      {
        text: "2021",
        value: "2021"
      },
      {
        text: "2022",
        value: "2022"
      },
      {
        text: "2023",
        value: "2023"
      },
      {
        text: "2024",
        value: "2024"
      },
      {
        text: "blank",
        value: "NULL"
      }
    ];
  } else if (
    columnNames === "eal_stud" ||
    columnNames === "fsm_stud" ||
    columnNames === "looked_after_stud" ||
    columnNames === "pupil_premium_stud" ||
    columnNames === "sen_status_stud" ||
    columnNames === "offer_made_sept" ||
    columnNames === "russell_group_sept" ||
    columnNames === "elephant_group_sept"
  ) {
    return [
      {
        text: "Yes",
        value: "Y"
      },
      {
        text: "No",
        value: "N"
      },
      {
        text: "blank",
        value: "NULL"
      }
    ];
  } else if (columnNames === "attending") {
    return [
      {
        text: "Yes",
        value: "Yes"
      },
      {
        text: "No",
        value: "No"
      },
      {
        text: "blank",
        value: "NULL"
      }
    ];
  } else if (columnNames === "year_group_stud") {
    return [
      {
        text: "7",
        value: "7"
      },
      {
        text: "8",
        value: "8"
      },
      {
        text: "9",
        value: "9"
      },
      {
        text: "10",
        value: "10"
      },
      {
        text: "11",
        value: "11"
      },
      {
        text: "11 L",
        value: "11 L"
      },
      {
        text: "12",
        value: "12"
      },
      {
        text: "13",
        value: "13"
      },
      {
        text: "13 L",
        value: "13 L"
      },
      {
        text: "blank",
        value: "NULL"
      }
    ];
  } else if (
    columnNames === "destination_sept" ||
    columnNames === "intended_destination_ks4" ||
    columnNames === "destination_oct"
  ) {
    const destinationFilter = [];
    if (seletedYear > 11) {
      Object.keys(destinationMayArr).forEach(function(k) {
        destinationFilter.push({
          text: destinationMayArr[k],
          value: destinationMayArr[k]
        });
      });
      destinationFilter.push({ text: "blank", value: "NULL" });
      return destinationFilter;
    } else {
      Object.keys(destinationArr).forEach(function(k) {
        destinationFilter.push({
          text: destinationArr[k],
          value: destinationArr[k]
        });
      });
      destinationFilter.push({ text: "blank", value: "NULL" });
      return destinationFilter;
    }
  } else if (
    columnNames === "intended_destination_may" ||
    columnNames === "intended_destination_16"
  ) {
    const destinationPostQuestionnaireFilter = [];
    Object.keys(destinationPostQuestionnaireArr).forEach(function(k) {
      destinationPostQuestionnaireFilter.push({
        text: destinationPostQuestionnaireArr[k],
        value: destinationPostQuestionnaireArr[k]
      });
    });
    destinationPostQuestionnaireFilter.push({ text: "blank", value: "NULL" });
    return destinationPostQuestionnaireFilter;
  } else if (columnNames === "sector_may" || columnNames === "sector_16") {
    const sectorsPostQuestionnaireArrFilter = [];
    Object.keys(sectorsPostQuestionnaireArr).forEach(function(k) {
      sectorsPostQuestionnaireArrFilter.push({
        text: sectorsPostQuestionnaireArr[k],
        value: sectorsPostQuestionnaireArr[k]
      });
    });
    sectorsPostQuestionnaireArrFilter.push({ text: "blank", value: "NULL" });
    return sectorsPostQuestionnaireArrFilter;
  } else if (
    columnNames === "he_intention_16" ||
    columnNames === "he_intention_y11"
  ) {
    const heintentionks4ArrFilter = [];
    Object.keys(heintentionks).forEach(function(k) {
      heintentionks4ArrFilter.push({
        text: heintentionks[k],
        value: heintentionks[k]
      });
    });
    heintentionks4ArrFilter.push({ text: "blank", value: "NULL" });
    return heintentionks4ArrFilter;
  } else if (columnNames === "level_sept" || columnNames === "level_oct") {
    const levelFilter = [];
    if (seletedYear > 11) {
      for (const [key, value] of Object.entries(sectorslevelMayArr)) {
        levelFilter.push({ text: value, value: key });
      }
      levelFilter.push({ text: "blank", value: "NULL" });
      return levelFilter;
    } else {
      for (const [key, value] of Object.entries(levelArr)) {
        levelFilter.push({ text: value, value: key });
      }
      levelFilter.push({ text: "blank", value: "NULL" });
      return levelFilter;
    }
  } else if (columnNames === "level_of_post_16_study") {
    const levelFilter = [];
    for (const [key, value] of Object.entries(levelPostStudyArr)) {
      levelFilter.push({ text: value, value: key });
    }
    levelFilter.push({ text: "blank", value: "NULL" });
    return levelFilter;
  } else if (
    columnNames === "sector_sept" ||
    columnNames === "sector_ks4" ||
    columnNames === "sector_oct"
  ) {
    const sectorFilter = [];
    if (seletedYear > 11) {
      Object.keys(sectorsMayArr).forEach(function(k) {
        sectorFilter.push({ text: sectorsMayArr[k], value: sectorsMayArr[k] });
      });
      sectorFilter.push({ text: "blank", value: "NULL" });
      return sectorFilter;
    } else {
      Object.keys(sectorsArr).forEach(function(k) {
        sectorFilter.push({ text: sectorsArr[k], value: sectorsArr[k] });
      });
      sectorFilter.push({ text: "blank", value: "NULL" });
      return sectorFilter;
    }
  } else if (columnNames === "provider_sept") {
    return spetProviderArr();
  } else if (columnNames === "provider_oct") {
    return octProviderArr();
  }
};

const findKey = (obj, val) => {
  const res = {};
  Object.keys(obj).forEach(key => {
    res[obj[key]] = key;
  });
  // if the value is not present in the object
  // return false
  return res[val] || "";
};

const heintentionks = JSON.parse(localStorage.getItem("heIntention_arr"))
  ? JSON.parse(localStorage.getItem("heIntention_arr"))
  : [];
const levelArr = JSON.parse(localStorage.getItem("level_arr"))
  ? JSON.parse(localStorage.getItem("level_arr"))
  : [];
const sectorsArr = JSON.parse(localStorage.getItem("sectors_arr"))
  ? JSON.parse(localStorage.getItem("sectors_arr"))
  : [];
const destinationArr = JSON.parse(localStorage.getItem("destination_arr"))
  ? JSON.parse(localStorage.getItem("destination_arr"))
  : [];
const septOnOffArr = { No: "N", Yes: "Y" };
//for post 16 master code
const destinationMayArr = JSON.parse(localStorage.getItem("destinationMay_arr"))
  ? JSON.parse(localStorage.getItem("destinationMay_arr"))
  : [];
const sectorsMayArr = JSON.parse(localStorage.getItem("sectorsMay_arr"))
  ? JSON.parse(localStorage.getItem("sectorsMay_arr"))
  : [];
const destinationPostQuestionnaireArr = JSON.parse(
  localStorage.getItem("destinationPostQuestionnaire_arr")
)
  ? JSON.parse(localStorage.getItem("destinationPostQuestionnaire_arr"))
  : [];
const sectorsPostQuestionnaireArr = JSON.parse(
  localStorage.getItem("sectorsPostQuestionnaire_arr")
)
  ? JSON.parse(localStorage.getItem("sectorsPostQuestionnaire_arr"))
  : [];
const sectorslevelMayArr = JSON.parse(
  localStorage.getItem("sectorslevelMay_arr")
)
  ? JSON.parse(localStorage.getItem("sectorslevelMay_arr"))
  : [];
const levelPostStudyArr = JSON.parse(localStorage.getItem("levelPostStudy_arr"))
  ? JSON.parse(localStorage.getItem("levelPostStudy_arr"))
  : [];

const seletedYear = JSON.parse(localStorage.getItem("selected_year_group"))
  ? JSON.parse(localStorage.getItem("selected_year_group"))
  : "";

//console.log(seletedYear);
const variableColumnsMapping = columns => {
  const keys = Object.keys(columns);
  return keys.map(key => {
    //console.log(columns[key].key);
    if (
      columns[key].key === "live_rag" ||
      columns[key].key === "rag_for_priority_16" ||
      columns[key].key === "rag_for_priority_ks4" ||
      columns[key].key === "careers_interview_date_1" ||
      columns[key].key === "careers_interview_date_2" ||
      columns[key].key === "careers_interview_date_3" ||
      columns[key].key === "eal_stud" ||
      columns[key].key === "fsm_stud" ||
      columns[key].key === "looked_after_stud" ||
      columns[key].key === "pupil_premium_stud" ||
      columns[key].key === "sen_status_stud" ||
      columns[key].key === "year_group_stud" ||
      columns[key].key === "provider_sept" ||
      columns[key].key === "provider_oct"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;

          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (columns[key].key === "attending") {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;

          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "updated_he_intention" ||
      columns[key].key === "he_intention_ks4" ||
      columns[key].key === "he_intention_y11" ||
      columns[key].key === "he_intention_16"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={findKey(heintentionks, value)}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "destination_oct" ||
      columns[key].key === "intended_destination_ks4" ||
      columns[key].key === "destination_sept" ||
      columns[key].key === "intended_destination_y11"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={
                        seletedYear > 11
                          ? findKey(destinationMayArr, value)
                          : findKey(destinationArr, value)
                      }
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "intended_destination_may" ||
      columns[key].key === "intended_destination_16"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={findKey(
                        destinationPostQuestionnaireArr,
                        value
                      )}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "sector_may" ||
      columns[key].key === "sector_16"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={findKey(sectorsPostQuestionnaireArr, value)}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "sector_y11" ||
      columns[key].key === "sector_oct" ||
      columns[key].key === "sector_ks4" ||
      columns[key].key === "sector_sept"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={
                        seletedYear > 11
                          ? findKey(sectorsMayArr, value)
                          : findKey(sectorsArr, value)
                      }
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "offer_made_sept" ||
      columns[key].key === "russell_group_sept" ||
      columns[key].key === "elephant_group_sept"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          //console.log(findKey(septOnOffArr, value));
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={
                    findKey(septOnOffArr, value)
                      ? findKey(septOnOffArr, value)
                      : "NULL"
                  }
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (
      columns[key].key === "level_sept" ||
      columns[key].key === "level_oct" ||
      columns[key].key === "expected_level" ||
      columns[key].key === "intended_level_y11"
    ) {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={
                    seletedYear > 11
                      ? sectorslevelMayArr[value]
                      : levelArr[value]
                  }
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else if (columns[key].key === "level_of_post_16_study") {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;
          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={
                    levelPostStudyArr[value] ? levelPostStudyArr[value] : ""
                  }
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        filters: filterArr(columns[key].key),
        filterMode: "tree",
        filterResetToDefaultFilteredValue: true,
        //onFilterValueChange: (value, record) => record.name.startsWith(value),
        sorter: (a, b) => {
          return null;
        }
      };
    } else {
      return {
        title: columns[key].title,
        dataIndex: columns[key].key,
        key: columns[key].key,
        width: 60,
        ellipsis: {
          showTitle: false
        },
        showSorterTooltip: {
          title: columns[key].title
        },
        render: (value, record, index) => {
          let color;

          if (
            columns[key].key === "live_rag" ||
            columns[key].key === "rag_for_priority_16" ||
            columns[key].key === "rag_for_priority_ks4"
          ) {
            if (value === 1) {
              color = "green";
            } else if (parseInt(value) === 2) {
              color = "#ffa62c";
            } else if (value === 3) {
              color = "red";
            }
          }
          return {
            props: {
              style: { color: color }
            },

            children: (
              <div
                className="editable-cell-value-wrap"
                style={{
                  textAlign: "center"
                }}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <Tooltip
                  id={columns[key].key + "_" + record.id + "_tool"}
                  placement="left"
                  title={value ? value : "NULL"}
                >
                  <Form.Item name={columns[key].key + "_" + record.id}>
                    <Input
                      style={{
                        margin: 0,
                        padding: 0,
                        width: "44px",
                        textAlign: "center"
                      }}
                      onBlur={event => {
                        event.stopPropagation();
                        save(
                          value,
                          columns[key].key + "_" + record.id,
                          record.id,
                          columns[key].key
                        );
                      }}
                      defaultValue={value}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            )
          };
        },
        sorter: (a, b) => {
          return null;
        }
      };
    }
  });
};

const getColumnMetaData = (title, children) => {
  return {
    title,
    children: [...children]
  };
};

const dataBinding = usersList => {
  const data = [];

  usersList.forEach(user => {
    const { fixed } = user.dt_column_values;
    let rowData = {
      ...fixed
    };

    Object.keys(columnNames).forEach(column => {
      const columnsData = user.dt_column_values[column]; // each column returned
      if (columnsData) {
        rowData = {
          ...rowData,
          ...columnsData
        };
      } else {
        return rowData;
      }
    });
    data.push({ ...rowData, ...user });
  });
  return data;
};

export {
  dataSets,
  dataSetsReverseColumnMapping,
  dataBinding,
  fixedColumnsMapping,
  variableColumnsMapping,
  getColumnMetaData
};
