import { Empty, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAppointments } from "../../../../../services/appointment";
import { ADVISER } from "../../../../../constants/rolesSubtypes";
import { useLocation } from "react-router";
import { MomentDateFormats } from "../../../../../constants";

// const mockAppointments = [
//     {
//         id: 1,
//         start: "2024-03-10T10:00:00Z",
//         title: "Appointment 1",
//         appointment_type: "type1",
//         total_time: 30,
//     },
//     {
//         id: 2,
//         start: "2024-03-11T11:00:00Z",
//         title: "Appointment 2",
//         appointment_type: "type2",
//         total_time: 45,
//     },
// ];

const UpcomingAppointments = ({ user }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getAppointmentsList = query => {
    setLoading(true);
    getAppointments({
      body: {
        sort_column: "id",
        page: 1,
        sort_order: "asc",
        date: moment().format(),
        page_limit: 3,
        filter_column: user.role_slug === ADVISER ? "adviser_id" : "student_id"
      },
      query: query || {}
    })
      .then(res => {
        setLoading(false);
        setEvents(res.appointments);
        // setEvents(mockAppointments);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (user && user.school_id && location.pathname === "/dashboard") {
      // Note: 'from_dashboard' query parameter was added to fetch appointments
      // based on school selected only from dashboard not from '/appointment' page
      getAppointmentsList({
        school_id: user.school_id,
        from_dashboard: 1
      });
    } else {
      getAppointmentsList();
    }
  }, [user.school_id]);

  useEffect(() => {
    setLoading(true);
    getAppointments({
      body: {
        sort_column: "id",
        page: 1,
        sort_order: "asc",
        date: moment().format(),
        page_limit: 3,
        filter_column: user.role_slug === ADVISER ? "adviser_id" : "student_id"
      }
    })
      .then(res => {
        setLoading(false);
        setEvents(res.appointments);
        // setEvents(mockAppointments);
      })
      .catch(() => setLoading(false));
  }, []);
  return (
    <div data-cy="upcoming-appointments-section" className="white-box">
      <h2 className="page-sub-heading">Upcoming Appointments</h2>
      <Skeleton loading={loading}>
        <ul data-cy="list" className="appoint-list">
          {events && Array.isArray(events) && events.length > 0 ? (
            events.map((event, index) => (
              <li key={index}>
                <a href="/">
                  {index === 0 && <span className="current">This Week</span>}
                  <h6>
                    <span>
                      {moment(event.start).format(
                        MomentDateFormats.LongDateFormat.LLLL
                      )}
                    </span>
                  </h6>
                  <p>{event.title}</p>
                  <p className="text-capitalize">{event.appointment_type}</p>
                  <p>
                    {event.total_time} <span>minutes</span>
                  </p>
                </a>
              </li>
            ))
          ) : (
            <Empty description="No appointments found" />
          )}
        </ul>
      </Skeleton>
      {events && Array.isArray(events) && events.length > 0 && (
        <Link
          to="/appointments"
          className="icon-button icon-button--iconcolor mt-4"
        >
          <span>
            <i className="fas fa-ellipsis-h" />
          </span>{" "}
          View full calendar
        </Link>
      )}
    </div>
  );
};

export default UpcomingAppointments;
