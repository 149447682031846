import React, { useEffect, useState } from "react";
import { Form, notification, Skeleton, Switch, TimePicker, Select } from "antd";
import moment from "moment";
import { PageMetaTags } from "Common";
import {
  createAdviserSettings,
  showAdviserSettings,
  updateAdviserSettings
} from "Services/appointment";
import { AdviserLeaveModal } from "../AdviserLeaveModal";
import "./style.scss";
import { MomentDateFormats } from "../../../../constants";
const { Option } = Select;

const flexCenter = {
  display: "flex",
  alignItems: "center"
};

const AdviserSettings = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [updateSettings, setUpdateSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolsDropdown, setSchoolsDropdown] = useState();
  const [days, setDays] = useState([
    { day: "Sunday", off_day: false },
    { day: "Monday", off_day: false },
    { day: "Tuesday", off_day: false },
    { day: "Wednesday", off_day: false },
    { day: "Thursday", off_day: false },
    { day: "Friday", off_day: false },
    { day: "Saturday", off_day: false }
  ]);

  useEffect(() => {
    form.setFieldsValue({
      Sunday: [undefined],
      Monday: [undefined],
      Tuesday: [undefined],
      Wednesday: [undefined],
      Thursday: [undefined],
      Friday: [undefined],
      Saturday: [undefined]
    });
  }, []);

  useEffect(() => {
    let schoolObjectArr = JSON.parse(localStorage.getItem("attached_school"));
    schoolObjectArr.data.shift();
    setSchoolsDropdown(schoolObjectArr);
    setLoading(true);
    showAdviserSettings()
      .then(response => {
        setLoading(false);
        if (response && Array.isArray(response.adviser_setting)) {
          setDays(response && response.adviser_setting);
          setUpdateSettings(true);

          response.adviser_setting.map(adviser => {
            //if (!adviser.off_day) {
            const slots = adviser.slots.map(slot => ({
              start: slot.start ? moment(slot.start) : "",
              end: slot.end ? moment(slot.end) : "",
              school_id: slot.school_id ? slot.school_id : ""
            }));
            form.setFieldsValue({ [adviser.day]: slots });
            /// }
            return false;
          });
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const renderSetTimeFields = day => {
    return (
      <div className="text-right">
        <Form.List name={day.day}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <div key={field.key} style={flexCenter}>
                  <div style={flexCenter}>
                    <div className="timepicker">
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, "school_id"]}
                        fieldKey={[field.fieldKey, "school_id"]}
                        rules={[{ required: true, message: " " }]}
                      >
                        <Select placeholder="Select school">
                          <Option value="">Select school</Option>
                          {schoolsDropdown &&
                            schoolsDropdown.data &&
                            schoolsDropdown.data.map(school => (
                              <Option value={school.id}>{school.name}</Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="timepicker">
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, "start"]}
                        fieldKey={[field.fieldKey, "start"]}
                        rules={[{ required: true, message: " " }]}
                      >
                        <TimePicker
                          placeholder="Start time"
                          format={
                            MomentDateFormats.CustomDateFormat
                              .twentyfourHourMinute
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="timepicker">
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, "end"]}
                        fieldKey={[field.fieldKey, "end"]}
                        rules={[{ required: day.off_day, message: " " }]}
                      >
                        <TimePicker
                          placeholder="End time"
                          format={
                            MomentDateFormats.CustomDateFormat
                              .twentyfourHourMinute
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    style={{
                      ...flexCenter,
                      marginLeft: 10,
                      minWidth: 86
                    }}
                  >
                    {fields.length > 1 && (
                      <div className="icon" onClick={() => remove(field.name)}>
                        <i className="fas fa-minus" />
                      </div>
                    )}
                    {fields.length - 1 === field.name && (
                      <div className="icon" onClick={add}>
                        <i className="fas fa-plus" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </div>
    );
  };

  const onFinish = values => {
    console.log(values);
    const newPayload = days.map(day => ({
      ...day,
      slots: !day.off_day
        ? values[day.day].map(s => ({
            school_id: s.school_id,
            start: moment.utc(s.start).format(),
            end: moment.utc(s.end).format()
          }))
        : [{ start: "", end: "" }]
    }));

    if (updateSettings) {
      updateAdviserSettings({ body: newPayload })
        .then(response => {
          if (response && response.success) {
            notification.success({
              message:
                response.message || "Adviser work setting updated successfully."
            });
          } else {
            notification.error({
              message: response.message || "Failed to update settings."
            });
          }
        })
        .catch(() => {
          notification.error({
            message: "Failed to update settings."
          });
        });
    } else {
      createAdviserSettings({ body: newPayload })
        .then(response => {
          if (response && response.success) {
            setUpdateSettings(true);
            notification.success({
              message:
                response.message || "Adviser work setting created successfully."
            });
          } else {
            notification.error({
              message: response.message || "Failed to create settings."
            });
          }
        })
        .catch(() => {
          notification.error({
            title: "Failed to create settings."
          });
        });
    }
  };

  return (
    <div data-cy="page-appointment-settings" className="content-panel">
      <PageMetaTags title="Appointment Settings" />
      <div className="student-booking">
        <h1 className="page-heading">Appointment Settings</h1>
        {/* <Link
          to="/"
          className="button-style button-style-green-btm button-style-normal-button"
        >
          Book Appointment
        </Link> */}
      </div>

      <div className="profile-wrapper adviser-settings">
        <div className="student-booking">
          <h4 className="heading">
            Let us know at what time are you available
          </h4>
          <input
            data-cy="button-days-off"
            type="submit"
            onClick={() => setVisible(true)}
            className="button-style button-style-green-btm button-style-normal-button"
            value="Days off"
          />
        </div>
        <Skeleton loading={loading}>
          <AdviserLeaveModal
            open={visible}
            onCancel={() => setVisible(false)}
          />
          <Form
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed
            form={form}
            defaultValue={{ sights: [undefined] }}
          >
            {days.map((day, index) => (
              <div key={day.day} className="day">
                <div
                  style={{
                    ...flexCenter,
                    marginBottom: 10
                  }}
                >
                  <div className="day-title">{day.day}</div>
                  <div className="text-right">
                    <Switch
                      checkedChildren="Open"
                      unCheckedChildren="Closed"
                      checked={!day.off_day}
                      onChange={val => {
                        const final = days;
                        final[index].off_day = !val;
                        setDays([...final]);
                      }}
                    />
                  </div>
                </div>

                {!day.off_day && renderSetTimeFields(day)}
              </div>
            ))}
            <div className="form-group text-right mt-4">
              <input
                type="submit"
                className="button-style button-style-green-btm button-style-normal-button"
                value="Save settings"
              />
            </div>
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default AdviserSettings;
