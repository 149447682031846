import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { Link } from "react-router-dom";
import { List, Modal, notification } from "antd";
import { STUDENT } from "../../../../constants/rolesSubtypes";
import {
  deleteTaskDoc,
  downloadDoc,
  getTaskDocs
} from "../../../../services/task";

const ViewDocsModal = ({ visible, setVisible, taskId, user }) => {
  const [docs, setDocs] = useState();
  const [loading, setLoading] = useState(false);

  const fetchTaskDocs = () => {
    setLoading(true);
    getTaskDocs({ pathParams: { taskId } })
      .then(response => {
        setDocs(response);
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetch docs` });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!taskId) {
      return;
    }
    fetchTaskDocs();
  }, [taskId]);

  const downloadDocuments = doc => {
    downloadDoc({ pathParams: { docId: doc.doc_id } })
      .then(response => {
        if (response && response.success) {
          const link = document.createElement("a");
          link.href = response.download_file;
          link.target = "_blank";
          link.setAttribute("download", doc.doc_name);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch(() => {});
  };

  const deleteDocument = docId => {
    setLoading(true);
    deleteTaskDoc({ pathParams: { docId } })
      .then(response => {
        fetchTaskDocs();
        if (response && response.success) {
          notification.success({
            message: response.message || "Task document deleted successfully!"
          });
        } else {
          notification.error({
            message: response.message || "Failed to delete task document."
          });
        }
      })
      .catch(error => {
        setLoading(false);
        notification.error({
          message: error.data.message || "Failed to delete task document."
        });
      });
  };

  return (
    <Modal
      data-cy="modal-task-documents"
      width={800}
      footer={null}
      visible={visible}
      afterClose={() => setDocs()}
      onCancel={() => setVisible(false)}
    >
      <div className="modal-title">Task Documents</div>
      <List
        loading={loading}
        bordered
        dataSource={(docs && docs.doc_list && docs.doc_list) || []}
        renderItem={item => (
          <List.Item
            actions={
              user &&
              user.role_slug === STUDENT && [
                <Link
                  to="#"
                  data-cy="download"
                  onClick={() => {
                    downloadDocuments(item);
                  }}
                  className="download-button"
                >
                  Download
                </Link>,
                <Link
                  to="#"
                  data-cy="delete"
                  onClick={() => {
                    deleteDocument(item.doc_id);
                  }}
                  className="delete-button"
                >
                  Delete
                </Link>
              ]
            }
          >
            <div className="doc">
              <span>
                <i className="fas fa-paperclip"></i>
              </span>
              <p>{item && item.doc_name}</p>
            </div>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default withContext(([{ user }]) => ({ user }), ViewDocsModal);
