import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, notification, Select, Skeleton } from "antd";
import { Option } from "antd/lib/mentions";
import TextArea from "antd/lib/input/TextArea";

import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";
import { PageMetaTags } from "../../../../components/common";
import { getLocationList } from "../../../../services/common";
import {
  getStudentData,
  saveStudentData
} from "../../../../services/destination";
import { deepMerge, isAnyFieldEditable } from "../common.js";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { formItems, TYPE } from "./formItemSchema";
import { STUDENT } from "../../../../constants";

function StudentData({ user }) {
  const pageHeading =
    user && user.role_slug === STUDENT ? "About Me" : "Student Data";
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [studentData, setStudentData] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group
  const [schools, setSchools] = useState([]);

  const getLocations = query => {
    setLoading(true);
    getLocationList({
      body: {
        sort_column: "created_at",
        sort_order: "desc",
        page_limit: 10,
        page: 1,
        is_header: 1 // "adviser"
      },
      query: query || {}
    })
      .then(response => {
        if (response.status) {
          setSchools(response);
          console.log(response);
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLocations();
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getStudentData({ query: payload })
      .then(response => {
        if (response.success && response.status_code === 200) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setStudentData(() => {
            setStudentData(newData);
          });
        } else {
          setStudentData(() => {
            setStudentData(formItems);
          });
        }
      })
      .catch(error => {
        console.log(error);
        setStudentData(() => {
          setStudentData(formItems);
        });
      })
      .finally(() => setGetLoading(false));
  }, [query.get("id")]);

  const onFinish = formValues => {
    setLoading(true);
    if (query.get("id")) {
      // Note: `student_id` is required when GET/PUT from `adviser`
      formValues.student_id = query.get("id");
    }
    saveStudentData({ body: formValues })
      .then(res => {
        notification.success({ message: `Saved the ${pageHeading}` });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to save ${pageHeading}`
        });
      })
      .finally(() => setLoading(false));
  };

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          {item.name === "school_id" ? (
            <Form.Item name={item.name}>
              {schools &&
                schools.data &&
                schools.data.map(school => {
                  if (school.id === item.value) {
                    return (
                      <Input
                        data-cy={item.name}
                        value={school.name}
                        readonly="true"
                        placeholder={item.placeholder}
                      />
                    );
                  }
                })}
            </Form.Item>
          ) : (
            <>
              <Form.Item name={item.name}>
                <Input
                  data-cy={item.name}
                  defaultValue={item.value}
                  readonly="true"
                  placeholder={item.placeholder}
                />
              </Form.Item>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderAsTextareaLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <TextArea
              data-cy={item.name}
              className="textarea-style"
              readonly="true"
              defaultValue={item.value}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !studentData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              data-cy={item.name}
              defaultValue={item.value}
              disabled={!item.editable}
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSelectElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !studentData["form_visible"]) {
      return renderAsLabel(item);
    }

    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-4">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Select
              data-cy={item.name}
              showSearch
              className={"w-100"}
              allowClear={true}
              style={{ width: 200 }}
              placeholder={item.placeholder}
              optionFilterProp="children"
              disabled={!item.editable}
              defaultValue={item.value}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option disabled>Select</Option>
              {item.options &&
                item.options.map((option, index) => (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                ))}

              {/*schools &&
                schools.data &&
                schools.data.map((school) => (
                  <Option key={school.id} value={school.id}>
                    {school.name}
                  </Option>
                ))*/}
            </Select>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderTextAreaElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !studentData["form_visible"]) {
      return renderAsTextareaLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <label className="labelstyle">{item.label}</label>
        <Form.Item name={item.name}>
          <TextArea
            data-cy={item.name}
            className="textarea-style"
            defaultValue={item.value}
            autoSize={{ minRows: 5, maxRows: 8 }}
          />
        </Form.Item>
      </div>
    );
  };

  return (
    <div data-cy="page-student-data" className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form
            data-cy="form-destination-tracker-student-data"
            form={form}
            onFinish={onFinish}
          >
            <div className="row">
              {studentData &&
                Object.keys(formItems).map(key => {
                  if (
                    studentData[key].type === TYPE.input &&
                    studentData[key].visibile
                  ) {
                    return renderInputElement(studentData[key]);
                  } else if (
                    studentData[key].type === TYPE.select &&
                    studentData[key].visibile
                  ) {
                    return renderSelectElement(studentData[key]);
                  } else if (
                    studentData[key].type === TYPE.textArea &&
                    studentData[key].visibile
                  ) {
                    return renderTextAreaElement(studentData[key]);
                  }
                })}

              {/*Submit*/}
              {editable && (
                <div className="col-12">
                  <div className="form-group pb-4">
                    <input
                      type="submit"
                      className="button-style button-style-green-btm button-style-normal-button"
                      disabled={loading}
                      value={loading ? "Loading..." : "Submit"}
                    />
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export { StudentData };
