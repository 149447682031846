import React from "react";
import { Breadcrumbs, PageMetaTags } from "../../../../components/common";

const renderBreadcrumbs = () => {
  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          name: "Resources",
          path: `/resources`
        },
        {
          name: "Careerometer",
          path: null
        }
      ]}
    />
  );
};

const Careerometer = () => {
  return (
    <div data-cy="page-careerometer" style={{ height: "90vh", width: "100%" }}>
      <PageMetaTags title="Resources - Careerometer" />
      {renderBreadcrumbs()}
      <h1>Resources - Careerometer</h1>
      <iframe
        src="https://www.lmiforall.org.uk/cm2/index.html"
        title="Web"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  );
};

export default Careerometer;
