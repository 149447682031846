import {
  Alert,
  Empty,
  Form,
  Modal,
  notification,
  Popconfirm,
  Select,
  Spin
} from "antd";
import React, { useEffect, useState } from "react";
import { getLocationList, getYearsList } from "../../../../services/common";
import { registerUser } from "../../../../services/user";

const AddStudentModal = props => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [yearsData, setYears] = useState([]);

  useEffect(() => {
    getLocationList({
      body: {
        sort_column: "created_at",
        sort_order: "desc",
        page_limit: 100000,
        page: 1,
        is_header: 1 // "adviser"
      }
    })
      .then(res => setSchools(res.data))
      .catch(() => {});
  }, []);

  useEffect(() => {
    getYearsList()
      .then(response => {
        setYears(response.data);
      })
      .catch(error => console.log("getYears", error));
  }, []);

  const onFinish = values => {
    setLoading(true);
    console.log(values);
    registerUser({ body: values })
      .then(res => {
        notification.success({
          message: "Register successful"
        });

        setTimeout(function() {
          props.onClose(false);
          window.location.reload();
        }, 3000);
      })
      .catch(err => {
        if (err && err.status === 422) {
          notification.error({
            message: Object.keys(err.data)
              .map(key => err.data[key][0])
              .join(" ")
          });
        } else {
          //notification.error({ message: "Failed to register your account." });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div data-cy="modal-add-student" className="modal fade" id="addStudent">
      <div className="row">
        <div className="col-12 col-md-6">
          <Modal
            title="Add Student"
            footer={null}
            visible={true}
            onCancel={() => props.onClose(false)}
            afterClose={() => {}}
          >
            <Form
              data-cy="form-add-student"
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="form-group-custom">
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Please enter your name" }
                  ]}
                >
                  <input
                    data-cy="input-name"
                    type="text"
                    placeholder="Name"
                    className="input-style"
                  />
                </Form.Item>
              </div>
              <div className="form-group-custom">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email"
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email"
                    }
                  ]}
                >
                  <input
                    data-cy="input-email"
                    type="email"
                    placeholder="Email"
                    className="input-style"
                  />
                </Form.Item>
              </div>
              <div className="form-group-custom">
                <Form.Item
                  name="school_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select school."
                    }
                  ]}
                >
                  <Select
                    data-cy="dropdown-school"
                    className="select-style"
                    placeholder="Select school"
                  >
                    {schools.slice(1).map(school => (
                      <Select.Option key={school.id} value={school.id}>
                        {school.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-group-custom">
                <Form.Item
                  name="year_group"
                  rules={[
                    {
                      required: true,
                      message: "Please select year group."
                    }
                  ]}
                >
                  <Select
                    data-cy="dropdown-year-group"
                    className="select-style"
                    placeholder="Select year group"
                  >
                    {yearsData.map((yearsData, index) => (
                      <Select.Option key={index} value={yearsData.id}>
                        {yearsData.id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="form-footer">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-fullwidth-button"
                  value={loading ? "Loading..." : "Save"}
                />
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddStudentModal;
