import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

export const StateContext = createContext();

export const GlobalStateProvider = ({ initialState, reducer, children }) => {
  const value = useReducer(reducer, initialState);
  const [state, dispatch] = value;
  if (process.env.REACT_APP_TESTING) {
    window.__setGlobalState = action => {
      dispatch(action);
    };
    window.__getGlobalState = () => state;
  } else {
    throw new Error("REACT_APP_TESTING variable is missing in the .env file");
  }
  return (
    <StateContext.Provider value={value || {}}>
      {children}
    </StateContext.Provider>
  );
};

export const withContext = (stateAndDispatchToProps, Component) => {
  return props => {
    return (
      <StateContext.Consumer>
        {contextValue => {
          const contextProps = stateAndDispatchToProps(contextValue);
          return <Component {...props} {...contextProps} />;
        }}
      </StateContext.Consumer>
    );
  };
};

GlobalStateProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  initialState: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired
};
