import React from "react";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const resourcesList = [
  {
    id: "skillso-meter",
    name: "Skillsometer",
    icon: require("../../../assets/icons/resources/skillsometer.png"),
    link: "/skillsometer"
  },
  {
    id: "star-exercise",
    name: "STAR Exercise",
    icon: require("../../../assets/icons/resources/star-exercise.png"),
    link: "/starexercise"
  },
  {
    id: "careero-meter",
    name: "Careerometer",
    icon: require("../../../assets/icons/resources/careerometer.png"),
    link: "/careerometer"
  },
  {
    id: "labour-market",
    name: "Labour Market Information",
    icon: require("../../../assets/icons/resources/grey-matter.png"),
    link: "/emsi-web-report"
  },
  {
    id: "useful-links",
    name: "Useful links & Docs",
    icon: require("../../../assets/icons/resources/links.png"),
    link: "/useful-links"
  }
];

const Resources = () => {
  return (
    <HeaderWrapper
      title="Resources"
      image={require("../../../assets/icons/header/icon-cogs.png")}
    >
      <div data-cy="page-resources">
        <Row gutter={[24, 24]} className="mb-4">
          {resourcesList.map((resource, index) => (
            <Col key={index} xl={8} lg={8} md={12} sm={24} xs={24}>
              <Link data-cy={resource.id} to={resource.link}>
                <div className="bg-white rounded-lg text-gray-500">
                  <div className="text-3xl p-4 font-thin">{resource.name}</div>
                  <div
                    style={{ background: "#18965f" }}
                    className="text-sm mt-2 h-56 rounded-b-lg flex justify-center items-center"
                  >
                    <img className="h-32" alt="about me" src={resource.icon} />
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </HeaderWrapper>
  );
};

export default Resources;
