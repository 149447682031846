import React, { useState } from "react";
import PropTypes from "prop-types";
import { withContext } from "Context";
import { signInSuccessAction } from "Actions/authActions";
import { PageMetaTags } from "Common";
import { Link } from "react-router-dom";
import { Form, notification } from "antd";
import { forgotPassword } from "../../../../services/user";
const ForgotPassword = ({ signInSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    forgotPassword({ body: values })
      .then(res => {
        setLoading(false);
        notification.success({
          message: "Email sent."
        });
      })
      .catch(err => {
        setLoading(false);
        if (err && err.data && err.data.message) {
          notification.error({
            message: err.data.message
          });
        } else {
          notification.error({
            message: "There was a problem sending the reset password link"
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const renderResetForm = () => {
    return (
      <Form onFinish={onFinish} autoComplete="off">
        <div className="form-group-custom">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email"
              },
              {
                type: "email",
                message: "Please enter a valid email"
              }
            ]}
          >
            <input
              data-cy="forgot-password-email"
              type="email"
              placeholder="Email"
              className="input-style"
            />
          </Form.Item>
        </div>
        <div className="form-footer">
          <input
            type="submit"
            disabled={loading}
            className="button-style button-style-green-btm button-style-fullwidth-button"
            value={loading ? "Loading..." : "Submit"}
          />
        </div>
      </Form>
    );
  };

  const STYLE_FORGET_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };
  const STYLE_LOGIN_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };

  return (
    <React.Fragment>
      <PageMetaTags title="Forgotten Password" />
      <div
        data-cy="page-forgot-password"
        className="container-fluid p-0 overflow-hidden"
      >
        <div className="row">
          <div className="col-12 col-md-7">
            <img
              style={{ ...STYLE_LOGIN_IMAGE }}
              src={require("../../../../assets/images/iProgressLogin_1920_7cblock.jpg")}
              alt="login"
            />
          </div>
          <div className="col-12 col-md-5">
            <div className="login-holder">
              <div className="login-inner text-center">
                <div
                  style={{
                    marginBottom: "25px",
                    textAlign: "center"
                  }}
                >
                  "
                  <img
                    style={{ ...STYLE_FORGET_IMAGE }}
                    src={require("../../../../assets/images/logo-small.png")}
                    alt="logo"
                  />
                </div>
                <h1 className="page-heading" style={{ textAlign: "center" }}>
                  Forgotten Password
                </h1>
                <p>
                  Enter your email address here to get a password reset link.
                </p>
                {renderResetForm()}
              </div>
              <Link to="/login" className="forget-link">
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  // signInSuccess: PropTypes.func.isRequired,
  // history: PropTypes.shape({
  //     push: PropTypes.func.isRequired,
  // }).isRequired,
};

export default withContext(contextValue => {
  if (!contextValue && !contextValue?.dispatch) {
    return {};
  }
  const [state, dispatch] = contextValue;
  return {
    signInSuccess: data => signInSuccessAction(data, dispatch)
  };
}, ForgotPassword);
