import { GCSE_OPTIONS, INTERESTS, LEVELS, SECTORS, TYPE } from "../common";

const APPLIED = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

const formItems = {
  applied: {
    name: "applied",
    label: "Applied",
    type: TYPE.radio,
    options: APPLIED
  },
  he_intention: {
    name: "he_intention",
    label: "HE Intention",
    type: TYPE.radio,
    options: INTERESTS
  },
  intended_course: {
    name: "intended_course",
    label: "Intended Course",
    type: TYPE.input
  },
  intended_destination: {
    name: "intended_destination",
    label: "Intended Destination",
    type: TYPE.radio,
    options: GCSE_OPTIONS
  },
  intended_level: {
    name: "intended_level",
    label: "Intended Level",
    type: TYPE.radio,
    options: LEVELS
  },
  intended_provider: {
    name: "intended_provider",
    label: "Provider",
    type: TYPE.input
  },
  sector: {
    name: "sector",
    label: "Sector",
    type: TYPE.radio,
    options: SECTORS
  }
};

export { formItems, APPLIED };
