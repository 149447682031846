import { Empty, notification, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { Link } from "react-router-dom";
import {
  changeTaskStatus,
  deleteTask,
  getTasks
} from "../../../../services/task";
import { TaskDropdownOverlay } from "../../Tasks/TaskDropdownOverlay";
import ViewDocsModal from "../../Tasks/ViewDocsModal";
import { ADVISER, STUDENT } from "../../../../constants";
import { storeTaskList } from "../../../../store/actions/taskActions";
import { deepCopy } from "../../../pages/DestinationTracker/common";

const ActionPlanCard = ({ user, tasks, storeTasks }) => {
  const [loading, setLoading] = useState(false);
  const [tasksList, setTasksList] = useState([]);
  // Todo: Requires `CreateTaskModal` to make edit functionality work
  const [taskModal, setTaskModal] = useState(false);
  const [taskDetails, setTaskDetails] = useState();
  const [viewDocModal, setViewDocModal] = useState(false);

  const fetchTasks = (query = {}) => {
    setLoading(true);
    const queryParams = { page: 1, ...query };
    getTasks({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: 4
      },
      query: queryParams
    })
      .then(response => {
        setTasksList(response.tasks || []);
        storeTasks(response);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (user && user.school_id) {
      fetchTasks({ school_id: user.school_id });
    } else {
      fetchTasks();
    }
  }, [user.school_id]);

  useEffect(() => {
    setTasksList(tasks && tasks.taskList);
  }, [tasks]);

  const removeTask = id => {
    deleteTask({ body: { task_id: id } })
      .then(response => {
        if (response && response.success) {
          notification.success({ message: "Successfully deleted the task" });
          fetchTasks();
        } else {
          throw Error;
        }
      })
      .catch(error => {
        console.log(error);
        notification.success({ message: "Failed to delete the task" });
      });
  };

  const changeStatus = task => {
    const status = task.status > 2 ? 2 : 3;
    updateTasks(status, task);
    changeTaskStatus({ pathParams: { taskId: task.id, statusId: status } })
      .then(res => {
        notification.success({
          message: res.message || "Task status has been updated successfully!"
        });
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to update task status!"
        });
      });
  };

  const updateTasks = (status, task) => {
    const newTasks = deepCopy(tasks);
    const index = newTasks.taskList.findIndex(item => item.id === task.id);
    newTasks.taskList[index].status = status;
    storeTasks({ tasks: newTasks.taskList, total_count: newTasks.total });
  };

  return (
    <Skeleton loading={loading}>
      <div className="table-responsive mobile-spacing">
        {Array.isArray(tasksList) && tasksList.length > 0 ? (
          <>
            <table className="table custom-table-style student">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Due date</th>
                </tr>
              </thead>
              <tbody data-cy="table">
                {(tasksList || []).map(task => (
                  <tr key={task.id}>
                    <td>
                      <label className="radio-style">
                        <input
                          onChange={() =>
                            user.role_slug === STUDENT && changeStatus(task)
                          }
                          type="checkbox"
                          className="form-check-input"
                          checked={task.status > 2}
                        />
                        <i />
                      </label>
                    </td>
                    <td>{task.description || ""}</td>
                    <td>
                      {/*
                      Note:
                        This Component will serve for both Student/Adviser Role
                        some props may be not required for student but
                        is required for adviser role:
                      */}
                      <TaskDropdownOverlay
                        task={task}
                        removeTask={removeTask}
                        setTaskModal={setTaskModal}
                        setTaskDetails={setTaskDetails}
                        setViewDocModal={setViewDocModal}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {user.role_slug === ADVISER && (
              <Link
                to={
                  user.role_slug === STUDENT
                    ? "/action-plan"
                    : user.role_slug === ADVISER
                    ? "/action-list"
                    : null
                }
                className="icon-button icon-button--iconcolor"
              >
                <span>
                  <i className="fas fa-ellipsis-h" />
                </span>{" "}
                View all actions{" "}
              </Link>
            )}

            {viewDocModal && (
              <ViewDocsModal
                visible={viewDocModal}
                setVisible={setViewDocModal}
                taskId={taskDetails && taskDetails.id}
              />
            )}
          </>
        ) : (
          <Empty description="No action plan found!" />
        )}
      </div>
    </Skeleton>
  );
};

export default withContext(
  ([{ tasks }, dispatch]) => ({
    tasks,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  ActionPlanCard
);
